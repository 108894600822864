import styled from '@emotion/styled'
import { Dropdown } from 'semantic-ui-react'
import { Search } from '@labsavvyapp/ui-components'
import { Grid } from '@labsavvyapp/components'

export const StyledProviderDropdown = styled(Dropdown)`
  width: 300px;
`

export const StyledKitSearch = styled(Search)`
  width: 290px;
  margin-left: 10px;
  margin-right: 15px;
`

export const FormSection = styled(Grid)`
  display: flex;
  background-color: #f7f6f6;
  padding: 10px 20px;
`

export const PanelSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding: 30px 20px;
`

export const StickyFooter = styled.div`
  display: flex;
  flex-flow: row;
  position: absolute;
  bottom: 0;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 70px;
  width: calc(100% - 60px);
  background-color: #f7f6f6;
  padding: 10px 30px;
`

export const EmptyContainer = styled.div`
  visibility: hidden;
`

export const ButtonGroup = styled.span`
  display: flex;
  gap: 10px;
`

export const ErrorMessage = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  word-break: break-word;
  color: #fa5252;
  font-size: calc(0.875rem - 0.125rem);
  line-height: 1.2;
  display: block;
  padding-top: 5px;
`
