import React from 'react'

import classnames from 'classnames'
import { ListRow, Form } from '@labsavvyapp/ui-components'

import { SaveMainPricingTest } from '../../../../graphql/main-pricing-list/mutations.js'

import sharedStyle from '../../../../styles/shared.module.css'
import style from './MainPricingRow.module.css'
import { useMutation } from 'react-apollo'
import { Input } from 'semantic-ui-react'

// FIXME: We have some checks, like to check if the first name exists.
// These needs to be removed once the DB is fixed.
export default function MainPricingRow({ items }) {
  const [saveMainPricingTest] = useMutation(SaveMainPricingTest)

  const handleSaveMainPricing = async (value, e) => {
    if (e.target) {
      value[e.target.name] = parseFloat(e.target.value)
      saveMainPricingTest({
        variables: {
          input: {
            id: value.id,
            provider_price: value.provider_price || 0,
            ls_price_override: value.ls_price || 0,
            standard_retail_price: value.standard_retail_price || 0,
          },
        },
      })
    }
  }

  return items.map((item, index) => (
    <ListRow key={index} className={classnames(sharedStyle.listRow, style.row)}>
      <div className={style.firstColumn}>{item.test_code}</div>
      <div className={style.secondColumn}>{item.test_name}</div>
      <div className={style.currencyColumn}></div>

      <div className={style.thirdColumn}>
        <Form>
          <Input
            name="provider_price"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            value={item.provider_price}
            style={{ cursor: 'default' }}
            onChange={(e) => handleSaveMainPricing(item, e)}
          />
        </Form>
      </div>
      <div className={style.currencyColumn}></div>
      <div className={style.fourthColumn}>
        <Form>
          {/* <input
            className={style.columnPackageName}
            name="ls_price"
            value={item.ls_price}
            type="number"
            required={true}
            onChange={(e) => handleSaveMainPricing(item, e)}
          /> */}
          <Input
            name="ls_price"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            value={item.ls_price}
            style={{ cursor: 'default' }}
            onChange={(e) => handleSaveMainPricing(item, e)}
          />
        </Form>
      </div>
      <div className={style.currencyColumn}></div>
      <div className={style.fifthColumn}>
        <Form>
          <Input
            name="standard_retail_price"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            value={item.standard_retail_price}
            style={{ cursor: 'default' }}
            onChange={(e) => handleSaveMainPricing(item, e)}
          />
        </Form>
      </div>
    </ListRow>
  ))
}
