import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useParams } from 'react-router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { Grid, Dropdown } from 'semantic-ui-react'
import {
  Button,
  CheckList,
  Form,
  InputField,
  RTEField,
  SlidePanel,
  SubmitButton,
  FormContext,
  CurrencyField,
  useSavingModals,
  FeaturedImageUploadField,
  ErrorModal,
} from '@labsavvyapp/ui-components'
import { fetchNext, hasMore } from '@labsavvyapp/ui-components/lib/utils'
import classnames from 'classnames'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import MainContainer from '../../../components/MainContainer/MainContainer'
import CopyIcon from '../../../components/Icons/CopyIcon'
import TestGroupSelectionPanel from '../../../components/TestGroupSelectionPanel/TestGroupSelectionPanel'
import Loader from '../../../components/Loader/Loader'
import PackageCategoryPanel from './PackageCategoryPanel'
import { PACKAGES } from '../../../config/routes'
import {
  GetCompendiumGroup,
  GetPackage,
  ListCompendiumPanels,
} from '../../../graphql/packages/queries.js'
import {
  CreatePackage,
  UpdatePackage,
} from '../../../graphql/packages/mutations.js'
import { ListProviders } from '../../../graphql/providers/queries.js'
import { ListPackageCategories } from '../../../graphql/package-categories/queries.js'
import sharedStyle from '../../../../src/styles/shared.module.css'
import style from './PackageFormPage.module.css'
import { usePanels } from './usePanels'
import { UploadImage } from '../../../graphql/files/mutations.js'

let fetchNextCompendiumPanels

const PackageFormPage = () => {
  const { push } = useHistory()
  const INITIAL_FORM_DATA = {
    name: '',
    ls_price: 0,
  }
  // Providers
  const { data: providersData, loading: providersLoading } =
    useQuery(ListProviders)
  const laboratoryOptions =
    (providersData &&
      providersData.listProviders.providers.map(({ _id, name }) => ({
        key: _id,
        value: _id,
        text: name,
      }))) ||
    []

  // Grab the Package ID form the URL
  const { packageId } = useParams()

  // Fetch packages categories list
  const { data } = useQuery(ListPackageCategories, {
    variables: {
      limit: 100,
      sort: {},
      filter: {},
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 1000 * 60,
  })

  const packageCategories = data
    ? camelizeKeys(data).listPackageCategories.packageCategories
    : []

  // Query: Package
  const { data: packageData, loading: packageLoading } = useQuery(GetPackage, {
    variables: {
      id: packageId,
    },
    skip: !packageId,
  })

  const [showTestGroupSelectionPanel, setShowTestGroupSelectionPanel] =
    useState(false)
  const [typeOption, setTypeOption] = useState('group')
  const [searchValue, setSearchValue] = useState('')
  const [hasMoreCompendiumPanels, setHasMoreCompendiumPanels] = useState()
  const [currentPackageCategory, setCurrentPackageCategory] = useState()
  // Set default provider id
  const [laboratory, setLaboratory] = useState(
    !providersLoading && laboratoryOptions[0].value,
  )

  const {
    selectedPanels,
    setSelectedPanels,

    getSelectedPanelsCheckList,

    hasPanelsChanged,
  } = usePanels({ packageData })

  // Groups
  const {
    data: compendiumPanelsData,
    refetch: refecthPanels,
    fetchMore: fetchMorePanels,
  } = useQuery(ListCompendiumPanels, {
    skip: providersLoading,
    variables: {
      providerId: !providersLoading && laboratoryOptions[0].value,
      type: typeOption,
    },
  })
  const compendiumPanels = camelizeKeys(compendiumPanelsData)
  // Goups > Pagination
  const panelsPage =
    compendiumPanels && compendiumPanels.listCompendiumPanels.page
  const panelsPages =
    compendiumPanels && compendiumPanels.listCompendiumPanels.pages

  // Group details
  const { refetch: refetchGroup } = useQuery(GetCompendiumGroup, {
    skip: true,
  })

  useEffect(() => {
    setHasMoreCompendiumPanels(
      hasMore({
        page: panelsPage,
        pages: panelsPages,
      }),
    )
    fetchNextCompendiumPanels = () =>
      fetchNext('listCompendiumPanels', 'compendium_panels', {
        page: panelsPage,
        fetchMore: fetchMorePanels,
      })
  }, [panelsPage, panelsPages, fetchMorePanels])

  const isEditPackageRefetch = () => {
    let params = {
      type: typeOption,
      search: searchValue,
      providerId: packageData.getPackage.provider._id,
    }
    refecthPanels(params)
  }

  const isNewPackageRefetch = () => {
    let params = { type: typeOption, search: searchValue }
    if (laboratory) params.providerId = laboratory
    refecthPanels(params)
  }

  useEffect(() => {
    if (!providersLoading) {
      packageData ? isEditPackageRefetch() : isNewPackageRefetch()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    typeOption,
    searchValue,
    providersLoading,
    packageData,
    laboratory,
    refecthPanels,
  ])

  //Set laboratory options (defaults to first entry)
  useEffect(() => {
    if (!laboratory && !providersLoading)
      setLaboratory(laboratoryOptions[0].value)
  }, [laboratory, providersLoading, laboratoryOptions])

  const parseFormInputData = (packageData) =>
    packageData && packageData.getPackage
      ? {
          name: packageData.getPackage.name,
          ls_price: packageData.getPackage.ls_price?.toFixed(2),
          partner_price: packageData.getPackage.partner_price?.toFixed(2),
          retail_price: packageData.getPackage.retail_price?.toFixed(2),
          summary: packageData.getPackage.marketing.summary,
          subtitle: packageData.getPackage.marketing.subtitle,
          details: packageData.getPackage.marketing.details,
          provider_id: packageData.getPackage.provider._id,
        }
      : {}

  // Decorate compendium groups and individual tests to highlight the
  // currently selected ones
  const decoratePanels = () => {
    if (!compendiumPanels) {
      return []
    }

    return compendiumPanels.listCompendiumPanels.compendiumPanels.map(
      (panel) => {
        const flatSelectedPanels =
          selectedPanels &&
          selectedPanels
            .reduce((acc, { panels }) => [...acc, ...panels], [])
            .filter((panel) => panel.code !== '')
        const selectedPanelIndex =
          flatSelectedPanels &&
          flatSelectedPanels.findIndex(
            (selectedPanel) => selectedPanel.code === panel.code,
          )

        return {
          ...panel,
          selected:
            selectedPanelIndex !== undefined && selectedPanelIndex !== -1,
        }
      },
    )
  }

  const decorateIndividualTests = () => {
    if (!compendiumPanels) {
      return []
    }

    return compendiumPanels.listCompendiumPanels.compendiumPanels.map(
      (panel) => {
        const flatSelectedTests =
          selectedPanels &&
          selectedPanels.reduce(
            (acc, { panels }) => [
              ...acc,
              ...panels
                .filter((panel) => panel.code === '')
                .reduce((accTests, { tests }) => [...accTests, ...tests], []),
            ],
            [],
          )
        const selectedPanelIndex =
          flatSelectedTests &&
          flatSelectedTests.findIndex(({ id }) => id === panel.tests[0].id)

        return {
          ...panel,
          selected:
            selectedPanelIndex !== undefined && selectedPanelIndex !== -1,
        }
      },
    )
  }

  // Helpers
  const hasTestsOrGroupsSelected = selectedPanels.length

  const canSubmit = ({ isDirty, hasErrors }) =>
    hasTestsOrGroupsSelected &&
    !hasErrors() &&
    (hasPanelsChanged() || isDirty())

  // Filter handlers
  const handleLaboratoryChange = (_, { value }) => {
    setLaboratory(value)
    refecthPanels({ providerId: value })
    setSelectedPanels([])
  }

  const handleGroupDetails = async (group) => {
    const { data } = await refetchGroup({
      providerId: laboratory,
      id: group,
    })

    return {
      id: group,
      tests: data.getCompendiumGroup.tests.map(({ _id: id, result }) => ({
        id,
        result,
      })),
    }
  }

  const handleAddTestClick = ({ value, type }) => {
    const index = selectedPanels.findIndex(
      (panel) => panel.category.id === currentPackageCategory,
    )

    selectedPanels[index] = {
      ...selectedPanels[index],
      panels: [
        ...selectedPanels[index].panels,
        ...value.map((panel) => ({ type, ...panel })),
      ],
    }

    setSelectedPanels((selectedPanels) => [...selectedPanels])

    setShowTestGroupSelectionPanel(false)
  }

  const handleDelete = (index, id, type) => {
    let newSelectedPanels = selectedPanels.slice()

    newSelectedPanels[index] = {
      ...newSelectedPanels[index],
      panels: newSelectedPanels[index].panels.filter((panel) =>
        type === 'individual' ? panel.tests[0].id !== id : panel.code !== id,
      ),
    }

    setSelectedPanels(newSelectedPanels)
  }

  const handleDeletePanelClick = (index) => (code) => {
    handleDelete(index, code, 'group')
  }

  const handleDeleteIndividualTestClick = (index) => (id) => {
    handleDelete(index, id, 'individual')
  }

  const addPackageCategoryPanel = () => {
    setSelectedPanels([
      { category: { id: '-1', name: null }, panels: [] },
      ...selectedPanels,
    ])
  }

  const updatePackageCategoryPanel = (index) => (packageCategoryId) => {
    const selectedCategory = packageCategories.find(
      ({ id }) => packageCategoryId === id,
    )

    selectedPanels[index] = {
      ...selectedPanels[index],
      category: {
        id: selectedCategory.id,
        name: selectedCategory.name,
      },
    }

    setSelectedPanels([...selectedPanels])
  }

  const deletePackageCategoryPanel = (index) => () => {
    showDeleteConfirmationModal(index)
  }

  // Save package handler
  const [createPackage] = useMutation(CreatePackage)

  // Update package handler
  const [updatePackage] = useMutation(UpdatePackage, {
    refetchQueries: [
      {
        query: GetPackage,
        variables: {
          id: packageId,
        },
      },
    ],
  })

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're saving the package, please wait...",
    savedMessage: 'The package has been saved.',
    callback: ({ id, data }) => {
      if (id) {
        updatePackage({
          variables: {
            id,
            data,
          },
        })
      } else {
        createPackage({
          variables: {
            data,
          },
        })
      }
    },
    onSuccess: () => !packageId && push(PACKAGES.base),
  })

  // Delete Modals
  const [deleteModals, { showConfirmationModal: showDeleteConfirmationModal }] =
    useSavingModals({
      savingMessage: "We're deleting the category, please wait...",
      savedMessage: 'The category has been deleted.',
      confirmationMessage: 'Are you sure you want to delete this category?',
      onConfirm: (index) => {
        selectedPanels.splice(index, 1)
        setSelectedPanels([...selectedPanels])
      },
    })

  const handleSubmit = async (formData) => {
    const categories = selectedPanels.map(({ category, panels }) => {
      return {
        category_id: category.id,
        panels: panels.map(({ code, tests }) => {
          return {
            type: code === '' ? 'individual' : 'group',
            tests: tests.map(({ id }) => id),
          }
        }),
      }
    })

    const data = {
      name: formData.name,
      ls_price: parseFloat(formData.ls_price),
      partner_price: parseFloat(formData.partner_price),
      retail_price: parseFloat(formData.retail_price),
      categories,
      marketing: {
        summary: formData.summary,
        details: formData.details,
        subtitle: formData.subtitle,
        cover_image_id: formData.cover_image_id,
      },
      //use state variable for new package else static variable for edit package
      provider_id: packageData
        ? packageData.getPackage.provider._id
        : laboratory,
    }

    showModals({ id: packageId, data })
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleOnDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      selectedPanels,
      result.source.index,
      result.destination.index,
    )

    console.log('items', items)

    setSelectedPanels(items)
  }

  const selectedPanelsCheckList = getSelectedPanelsCheckList()

  const [upload, uploadData] = useMutation(UploadImage)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  async function uploadFile(file) {
    try {
      const payload = await upload({ variables: { file } })
      return (
        payload &&
        payload.data &&
        payload.data.uploadImage &&
        payload.data.uploadImage._id
      )
    } catch (error) {
      setErrorMessage('Error uploading image')
      setShowErrorModal(true)
      throw new Error(error)
    }
  }

  const featuredImage = packageData?.getPackage?.marketing?.cover_image?.url

  return (
    <>
      {/* Modals */}
      {modals}
      {deleteModals}

      <ErrorModal
        message={errorMessage}
        onCloseClick={() => setShowErrorModal(false)}
        open={showErrorModal}
      />

      {/* Main container */}
      <MainContainer>
        <Helmet>
          {packageId ? <title>Edit Package</title> : <title>New Package</title>}
        </Helmet>

        <Form
          className={style.formContainer}
          initialFormData={
            packageId ? parseFormInputData(packageData) : INITIAL_FORM_DATA
          }
          onSubmit={handleSubmit}
        >
          <header className={style.header}>
            <h1
              className={classnames(
                sharedStyle.pageTitle,
                sharedStyle.noMargin,
              )}
            >
              {packageId ? 'Edit Package' : 'New Package'}
            </h1>

            <div className={style.laboratoryWrapper}>
              <span>Laboratory:</span>
              <Dropdown
                selection
                className={style.laboratoryDropdown}
                onChange={handleLaboratoryChange}
                options={laboratoryOptions}
                // if new package allow to change lab else if edit package set static provider id from package
                value={
                  packageId
                    ? packageData && packageData.getPackage.provider._id
                    : laboratory
                }
                loading={laboratoryOptions.length === 0}
                //do not allow selection of laboratory on edit package
                disabled={packageId ? true : false}
                name="provider_id"
              />
            </div>
          </header>

          <FormContext.Consumer>
            {({ isDirty, hasErrors }) => (
              <>
                <div className={style.packageDetails}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column computer={7} tablet={16}>
                        <InputField
                          className={style.columnPackageName}
                          name="name"
                          label="Package Name"
                          required={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={2} tablet={16}>
                        <CurrencyField
                          className={style.columnPackagePrice}
                          name="ls_price"
                          label="LabSavvy Price"
                          required={true}
                        />
                      </Grid.Column>
                      <Grid.Column computer={2} tablet={16}>
                        <CurrencyField
                          className={style.columnPackagePrice}
                          name="partner_price"
                          label="Partner Price"
                        />
                      </Grid.Column>
                      <Grid.Column computer={2} tablet={16}>
                        <CurrencyField
                          className={style.columnPackagePrice}
                          name="retail_price"
                          label="Retail Price"
                        />
                      </Grid.Column>
                      <Grid.Column computer={3} tablet={16}>
                        <div className={style.columnNewCategory}>
                          <Button
                            className={style.buttonNewCategory}
                            variant="secondary"
                            onClick={() => addPackageCategoryPanel()}
                            disabled={
                              selectedPanelsCheckList.length ===
                              packageCategories.length
                            }
                            data-test="button-new-category"
                          >
                            New Category
                          </Button>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                {packageId && packageLoading && (
                  <div className={style.loaderPackage}>
                    <Loader />
                  </div>
                )}
                <div className={style.checklistContainer}>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="droppableCategories">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {packageCategories.length !== 0 &&
                            selectedPanelsCheckList.length !== 0 &&
                            selectedPanelsCheckList.map((item, index) => (
                              <div
                                className={style.categoryWrapper}
                                key={item.id + index.toString()}
                              >
                                <PackageCategoryPanel
                                  packageCategories={packageCategories}
                                  selectedPanels={selectedPanels}
                                  packageCategory={item.category}
                                  setShowTestGroupSelectionPanel={
                                    setShowTestGroupSelectionPanel
                                  }
                                  onUpdate={updatePackageCategoryPanel(index)}
                                  onDeleteCategory={deletePackageCategoryPanel(
                                    index,
                                  )}
                                  setCurrentPackageCategory={
                                    setCurrentPackageCategory
                                  }
                                  index={index}
                                >
                                  {item.panels
                                    .filter(({ panel }) => panel.code !== '')
                                    .map(({ panel, tests }) => (
                                      <CheckList
                                        key={panel.code}
                                        title={
                                          <div className={style.checkListTest}>
                                            <span
                                              className={
                                                style.checkListTestCode
                                              }
                                            >
                                              {panel.code}
                                            </span>
                                            <span>{panel.name}</span>
                                          </div>
                                        }
                                        group={{
                                          text: panel.name,
                                          value: panel.code,
                                          checked: panel.checked,
                                        }}
                                        items={tests}
                                        onDeleteClick={handleDeletePanelClick(
                                          index,
                                        )}
                                        deepCompareValues={[
                                          panel.id,
                                          panel.checked,
                                        ]}
                                        showCheckboxes={false}
                                      />
                                    ))}

                                  {item.individualTests.length !== 0 && (
                                    <CheckList
                                      title="Individual Tests"
                                      items={item.individualTests}
                                      onDeleteClick={handleDeleteIndividualTestClick(
                                        index,
                                      )}
                                      showCheckboxes={false}
                                    />
                                  )}
                                </PackageCategoryPanel>
                              </div>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className={style.buttonsContainer}>
                  <Button
                    className={style.buttonCancel}
                    data-test="button-new-package-cancel"
                    onClick={() => push(PACKAGES.base)}
                    variant={Button.variant.basic}
                  >
                    Cancel
                  </Button>
                  <SubmitButton
                    className={style.buttonSavePackage}
                    disabled={!canSubmit({ isDirty, hasErrors })}
                  >
                    Save Package
                  </SubmitButton>
                </div>

                <SlidePanel
                  openButtonTopPosition="78px"
                  openIcon={<CopyIcon />}
                  title="Package Marketing"
                >
                  <FeaturedImageUploadField
                    image={featuredImage}
                    label="Featured Image"
                    name="cover_image_id"
                    showLoader={uploadData.loading}
                    uploadFn={uploadFile}
                    removeFn={() => {}}
                  />
                  <InputField
                    className={style.subtitle}
                    label="Subtitle"
                    name="subtitle"
                    rows={1}
                    textarea
                  />
                  <InputField
                    className={style.summary}
                    label="Summary"
                    name="summary"
                    rows={8}
                    textarea
                  />
                  <RTEField
                    className={style.details}
                    label="Details"
                    name="details"
                  />
                </SlidePanel>
              </>
            )}
          </FormContext.Consumer>
        </Form>

        <TestGroupSelectionPanel
          open={showTestGroupSelectionPanel}
          tests={decorateIndividualTests()}
          groups={decoratePanels()}
          onSearchChange={(search) => setSearchValue(search.value)}
          onGroupClick={handleGroupDetails}
          onCancelClick={() => setShowTestGroupSelectionPanel(false)}
          onAddClick={handleAddTestClick}
          onTypeChange={(typeOption) => setTypeOption(typeOption)}
          fetchNext={fetchNextCompendiumPanels}
          hasMore={hasMoreCompendiumPanels}
        />
      </MainContainer>
    </>
  )
}

export default PackageFormPage
