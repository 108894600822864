import gql from 'graphql-tag'

export const GetCompendiumGroup = gql`
  query GetCompendiumGroup($providerId: MongoID!, $id: ID!) {
    getCompendiumGroup(providerId: $providerId, id: $id) {
      tests {
        _id
        result {
          name
          code
        }
      }
    }
  }
`

export const GetPackage = gql`
  query GetPackage($id: MongoID!) {
    getPackage(id: $id) {
      name
      ls_price
      partner_price
      additional_fee
      retail_price
      provider {
        _id
      }
      categories {
        category {
          _id
          name
        }
        panels {
          code
          name
          tests {
            _id
            order {
              name
              code
            }
            result {
              name
              code
            }
          }
        }
      }
      marketing {
        summary
        details
        subtitle
        cover_image {
          url
        }
      }
      provider {
        _id
      }
    }
  }
`

export const ListPackages = gql`
  query ListPackages(
    $page: NonNegativeInt
    $filter: PackageFilter
    $sort: PackageSort
  ) {
    listPackages(limit: 120, page: $page, filter: $filter, sort: $sort) {
      packages {
        _id
        name
        partner_price
      }
      page
      pages
      total
    }
  }
`

export const ListPartnerProjectPackages = gql`
  query ListPartnerProjectPackages(
    $partnerId: MongoID!
    $projectId: MongoID!
    $providerId: MongoID!
    $limit: PositiveInt = 40
    $filter: NameFilter
    $sort: NameSort
    $page: NonNegativeInt = 1
  ) {
    listPartnerProjectPackages(
      partner_id: $partnerId
      project_id: $projectId
      provider_id: $providerId
      limit: $limit
      filter: $filter
      sort: $sort
      page: $page
    ) {
      packages {
        name
        commission
        package {
          _id
          ls_price
        }
      }
      page
      pages
      total
    }
  }
`

export const ListCompendiumPanels = gql`
  query ListCompendiumPanels(
    $limit: PositiveInt = 20
    $page: PositiveInt = 1
    $type: CompendiumPanelTypeEnum!
    $search: RegExp
    $providerId: MongoID!
    $sort: CompendiumSort
  ) {
    listCompendiumPanels(
      limit: $limit
      page: $page
      filter: { type: $type, providerId: $providerId, search: $search }
      sort: $sort
    ) {
      compendium_panels {
        name
        code
        tests {
          _id
          order {
            name
            code
          }
          result {
            name
            code
          }
        }
      }
      page
      pages
      total
    }
  }
`
