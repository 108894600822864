import React from 'react'

import {
  InfiniteScrollList,
  // ListEmpty,
  List,
  ListHeader,
  ListLoader,
  Card,
} from '@labsavvyapp/ui-components'
import { Icon } from 'semantic-ui-react'

import {
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'

import style from './LabsList.module.css'
import PackagesListRow from './LabsListRow'

export default function LabsList({
  data,
  fetchMore,
  loading,
  onDeleteClick,
  onSetDefaultLab,
  defaultProvider,
}) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (data) {
    items = data.listPartnerProjectProviders
    // pagination.page = data.listPartnerProjectPackages.page
    // pagination.pages = data.listPartnerProjectPackages.pages
  }

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Lab Name',
            className: style.providerNameColumn,
          },
          {
            name: 'Account Number',
            className: style.providerNameColumn,
          },
          {
            name: '',
            className: style.deleteColumn,
          },

          {
            name: <Icon disabled name="trash" />,
            className: style.deleteColumn,
          },
        ]}
      />
      <InfiniteScrollList
        dataLength={items.length}
        scrollableTarget="packages-list"
        hasMore={hasMore(pagination)}
        next={() =>
          fetchNext('listPartnerProjectPackages', 'packages', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && isEmpty(items) && (
          <Card data-test="EmptyCard" emptyCard>
            <b>No labs were found associated to this project. </b> You may add
            one using the "Add Kit to Project" button above.
          </Card>
        )}

        {!isEmpty(items) && (
          <PackagesListRow
            items={items}
            onDeleteClick={onDeleteClick}
            onSetDefaultLab={onSetDefaultLab}
            defaultProvider={defaultProvider}
          />
        )}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
