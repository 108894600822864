import React from 'react'
// import { useHistory } from 'react-router'

export default function EmailManagementPage() {
  // const { push } = useHistory()

  return (
    <div>
      <h1>Email</h1>
    </div>
  )
}
