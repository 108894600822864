import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams } from 'react-router'
import { Grid } from 'semantic-ui-react'
import { Form, Button, useSavingModals } from '@labsavvyapp/ui-components'
import { GetPartnerColumnSettings } from '../../../../../graphql/partner/queries.js'
import { UpdatePartner } from '../../../../../graphql/partner/mutations.js'
import styled from '@emotion/styled'
import { Card } from './components/Card'

import { DEFAULT_COLUMNS } from './constants.js'

const style = {
  width: 450,
}

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row nowrap;
  max-width: 400px;
  border-bottom: 2px solid #f7f6f6;
  margin-bottom: 10px;
  padding: 0px 40px;
`

const CardContainer = styled.div`
  width: 100%;
  flex-direction: row nowrap;

  &hover {
    background-color: red;
  }
`

const ControlsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`

export default function ColumnSettingsTab() {
  const { partnerId } = useParams()
  const [updatePartner] = useMutation(UpdatePartner)
  const [cards, setCards] = useState([])

  const { data: partnerData, refetch } = useQuery(GetPartnerColumnSettings, {
    variables: {
      id: partnerId,
    },
  })

  const lab_report_config = partnerData?.getPartner?.config?.lab_report ?? null

  const [partnerDataConfigLabReport, setPartnerDataConfigLabReport] = useState()

  // Initial load of partner column settings data
  useEffect(() => {
    setPartnerDataConfigLabReport(lab_report_config)

    if (partnerData) {
      const isCurrentColumnSettingsCountSame =
        lab_report_config.columns.length === DEFAULT_COLUMNS.length

      if (isCurrentColumnSettingsCountSame) {
        const parseStringifiedColumnData = lab_report_config.columns.map(
          (column) => {
            return JSON.parse(column)
          },
        )
        setCards(parseStringifiedColumnData)
      } else {
        setCards(DEFAULT_COLUMNS)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerData])

  useEffect(() => {
    const stringifyColumnData = cards.map((card) => {
      return JSON.stringify(card)
    })

    setPartnerDataConfigLabReport({ columns: stringifyColumnData })
  }, [cards])

  // Modals
  const [modals, { showModals: saveColumnSettingsConfig }] = useSavingModals({
    savingMessage: "We're saving the partner, please wait...",
    savedMessage: 'The partner has been saved.',
    callback: async () => {
      await updatePartner({
        variables: {
          id: partnerId,
          data: {
            config: {
              lab_report: partnerDataConfigLabReport,
            },
          },
        },
      })
    },
  })

  const handleMoveCard = (currentIndex, indexToMove) => {
    const dataToMove = cards.find((item, index) => index === currentIndex)
    const cardListWithoutDataToMove = cards.filter(
      (card, index) => index !== currentIndex,
    )
    cardListWithoutDataToMove.splice(indexToMove, 0, dataToMove)

    setCards(cardListWithoutDataToMove)
  }

  const handleRemoveCard = (columnId) => {
    const updatedList = cards.filter((card) => card.id !== columnId)
    setCards(updatedList)
  }

  const handleEditCardList = (
    column_name,
    column_width,
    is_active,
    column_key,
  ) => {
    const updatedDataArray = cards.map((item) => {
      if (item.column_key === column_key) {
        return {
          ...item,
          column_name: column_name,
          column_width: parseInt(column_width),
          is_active: is_active,
          column_key: column_key,
        }
      }
      return item
    })

    setCards(updatedDataArray)
    refetch()
  }

  return (
    <>
      {/* Modals */}
      {modals}

      {/* Form */}
      <Form
        initialFormData={partnerData && partnerData.getPartner.config}
        onSubmit={() => {}}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <Header>
                <span>Column Name</span>
                <span>Column Width (%)</span>
              </Header>

              <div style={style}>
                {cards.map((card, index) => {
                  return (
                    <CardContainer key={card.column_key + '_' + index}>
                      <Card
                        key={card.id}
                        index={index}
                        columnName={card.column_name}
                        columnWidth={card.column_width}
                        isActive={card.is_active}
                        columnKey={card.column_key}
                        handleMoveCard={handleMoveCard}
                        removeCard={handleRemoveCard}
                        editCardList={handleEditCardList}
                        isFirstItem={index === 0}
                        isLastItem={index === cards.length - 1}
                      />
                    </CardContainer>
                  )
                })}
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <ControlsContainer>
                <Button onClick={saveColumnSettingsConfig}>Save Changes</Button>
              </ControlsContainer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  )
}
