import React from 'react'
import MainContainer from '../../../../../components/MainContainer/MainContainer.js'
import { Button } from '@labsavvyapp/components'
import { GetPartnerInternalEventSettings } from '../../../../../graphql/partner/queries.js'
import styled from '@emotion/styled'
import { useQuery } from 'react-apollo'
import { useHistory, useParams } from 'react-router'
import { InternalEventsList } from './InternalEventsList'

const AddInternalEvent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: -50px;
`

export const InternalEventsPage = () => {
  const { partnerId } = useParams()
  const { push } = useHistory()

  const {
    data: internalEvents,
    loading: internalEventsLoading,
    fetchMore: fetchMoreInternalEvents,
  } = useQuery(GetPartnerInternalEventSettings, {
    variables: { id: partnerId },
  })

  return (
    <MainContainer>
      <AddInternalEvent>
        <Button
          onClick={() => push('add-internal-event')}
          variant="filled"
          uppercase
        >
          Add Internal Event
        </Button>
      </AddInternalEvent>
      <InternalEventsList
        data={internalEvents?.getPartner?.hooks?.internal}
        loading={internalEventsLoading}
        fetchMore={fetchMoreInternalEvents}
      />
    </MainContainer>
  )
}
