export const CATEGORIES_FILTER = {
  domain: 'MASTERDATA',
  category: 'KIT',
  subcategory: 'CATEGORY',
}

export const BRANDS_FILTER = {
  domain: 'MASTERDATA',
  category: 'KIT',
  subcategory: 'BRAND',
}

export const FULFILLMENTS_FILTER = {
  domain: 'MASTERDATA',
  category: 'VENDOR',
  subcategory: 'FULFILLMENT',
}

export const KIT_FORM_VALIDATIONS = {
  name: (value) => (value.length < 1 ? 'Cannot be empty' : null),
  description: (value) => (value.length < 1 ? 'Cannot be empty' : null),
  provider_id: (value) => (value.length < 1 ? 'Cannot be empty' : null),
  sku_kit_id: (value) => (value.length < 1 ? 'Cannot be empty' : null),
  sku_lab_id: (value) => (value.length < 1 ? 'Cannot be empty' : null),
  fulfillment: (value) => (value?.length < 1 ? 'Cannot be empty' : null),
  compendia_codes: (value) =>
    value?.length < 1
      ? 'There must be at least one panel selected for this kit'
      : null,
}

export const KIT_FORM_INITIAL_VALUES = {
  name: '',
  description: '',
  short_name: '',
  sku_kit_id: '',
  sku_lab_id: '',
  code: '',
  category: '',
  brand: '',
  fulfillment: '',
  provider_id: '',
  compendia_codes: [],
  meta: [],
}
