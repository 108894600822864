import React from 'react'

import { Grid } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useParams, useLocation } from 'react-router'
import { camelizeKeys } from 'humps'
import {
  Form,
  SelectField,
  SubmitButton,
  InputField,
  // PercentageField,
  validate,
  useSavingModals,
} from '@labsavvyapp/ui-components'
import { useHistory, generatePath } from 'react-router'
import { ListProviders } from '../../../../graphql/providers/queries'
import { AddPartnerProjectProvider } from '../../../../graphql/labs/mutations.js'
import sharedStyles from '../../../../styles/shared.module.css'
import { PARTNERS } from '../../../../config/routes'

export default function AddLabTab() {
  const location = useLocation()
  const { push } = useHistory()
  const { partnerId, projectId } = useParams()

  // Modals
  const [modals, { showModals }] = useSavingModals({
    savingMessage: "We're adding the package, please wait...",
    savedMessage: 'Package added.',
    callback: async ({ providerId, accountNumber }) => {
      await addPartnerProjectProvider({
        variables: {
          partnerId,
          projectId,
          providerId,
          accountNumber,
        },
      })
      setTimeout(() => {
        push(
          generatePath(PARTNERS.projects.admin.section, {
            partnerId: partnerId,
            projectId: projectId,
            section: 'labs',
          }),
        )
      }, 3000)
    },
    onError: (error) => error,
  })

  // Fetch packages in order to populate the dropdown
  const { data } = useQuery(ListProviders)
  const providersData = camelizeKeys(data)
  const providersOptions = providersData
    ? providersData.listProviders.providers.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
      }))
    : []

  const [addPartnerProjectProvider] = useMutation(AddPartnerProjectProvider)

  return (
    <>
      <Form
        onSubmit={showModals}
        initialFormData={{
          providerId: location?.state?.provider.provider.id,
          accountNumber: location?.state?.provider.accountNumber,
        }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SelectField
                name="providerId"
                label="Lab Name"
                options={providersOptions}
                validate={validate.notEmpty()}
                disabled={Boolean(location?.state?.provider)}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="accountNumber"
                label="Account Number"
                type="number"
                validate={validate.notEmpty()}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <SubmitButton className={sharedStyles.marginTop10}>
                {Boolean(location?.state?.provider) ? 'Edit ' : 'Add '}
                Lab to Project
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      {/* Modals */}
      {modals}
    </>
  )
}
