import gql from 'graphql-tag'

export const GET_KIT = gql`
  query GetKit($id: MongoID!) {
    getKit(id: $id) {
      _id
      name
      description
      short_name
      sku_kit_id
      sku_lab_id
      code
      category
      brand
      fulfillment
      provider_id
      compendia_codes
      meta {
        code
        value
      }
    }
  }
`

export const GET_KIT_LIST = gql`
  query ListKits(
    $limit: PositiveInt = 200
    $page: NonNegativeInt = 1
    $filter: KitFilter
  ) {
    listKits(limit: $limit, page: $page, filter: $filter) {
      kits {
        _id
        name
        description
        short_name
        sku_kit_id
        sku_lab_id
        code
        category
        brand
        fulfillment
        provider_id
        compendia_codes
        meta {
          code
          value
        }
      }
    }
  }
`
