import React from 'react'

import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  Card,
  ListEmpty,
} from '@labsavvyapp/ui-components'
import {
  RefreshButton,
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/components'

import style from './PartnerPricingList.module.css'
import PartnerPricingRow from './PartnerPricingRow'

export default function PartnerPricingList({
  data,
  fetchMore,
  loading,
  handleRefetch,
}) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (data) {
    items = data.partnerPricing.data
    pagination.page = data.partnerPricing.page
    pagination.pages = data.partnerPricing.pages
    pagination.total = data.partnerPricing.total
  }

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Test Code',
            className: style.codeColumn,
          },
          { name: 'Test Name', className: style.nameColumn },
          {
            name: 'LS Cost',
            className: style.priceColumn,
          },
          {
            name: 'LS Standard Price',
            className: style.priceColumn,
          },
          {
            name: 'Standard Retail Price',
            className: style.priceColumn,
          },
          {
            name: 'Contract Price',
            className: style.priceColumn,
          },
          {
            name: 'Retail Price',
            className: style.priceColumn,
          },
        ]}
      />

      <InfiniteScrollList
        dataLength={items.length}
        scrollableTarget="partner-pricing-list"
        hasMore={hasMore({ page: pagination.page, pages: pagination.pages })}
        next={() =>
          fetchNext('partnerPricing', 'data', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && !items && (
          <Card data-test="EmptyCard" emptyCard>
            <b>No pricings were found. </b> If you feel this is an error, you
            may try to <RefreshButton />
            &mdash; this may be a momentary issue.
          </Card>
        )}

        {isEmpty(items) && <ListEmpty message="No pricing data found. " />}

        {!isEmpty(items) && (
          <PartnerPricingRow items={items} handleRefetch={handleRefetch} />
        )}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
