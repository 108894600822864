import React, { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useHistory } from 'react-router'
import { Dropdown } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'
import { ToolbarHeader } from '@labsavvyapp/components'
import { Search, Button } from '@labsavvyapp/ui-components'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { PACKAGE_CATEGORIES } from '../../../config/routes'
import { SORT_BY_OPTIONS } from './constants'
import PackageCategoriesList from './PackageCategoriesList/PackageCategoriesList'
import { updateURLParameter } from '../../../utils/urls'
import { ListPackageCategories } from '../../../graphql/package-categories/queries.js'
import style from './PackageCategoriesPage.module.css'

export default function PackageCategoriesPage() {
  const { search: locationSearch } = useLocation()
  const { push } = useHistory()

  const query = new URLSearchParams(locationSearch)
  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')

  const getQueryVariables = useCallback(() => {
    const variables = {
      limit: 30,
      sort: {},
      filter: {},
    }

    switch (sortBy) {
      case SORT_BY_OPTIONS.nameAZ.key:
        variables.sort.name = SORT_BY_OPTIONS.nameAZ.value
        break
      case SORT_BY_OPTIONS.nameZA.key:
        variables.sort.name = SORT_BY_OPTIONS.nameZA.value
        break
      default:
        break
    }

    if (search) {
      variables.filter.name = search
    }

    return variables
  }, [search, sortBy])

  // Fetch packages list
  const { data, networkStatus, fetchMore, refetch } = useQuery(
    ListPackageCategories,
    {
      variables: getQueryVariables(),
      notifyOnNetworkStatusChange: true,
    },
  )

  const packageCategoriesData = data && camelizeKeys(data)

  useEffect(() => {
    refetch({
      ...getQueryVariables(),
      page: 1,
    })
  }, [sortBy, search, getQueryVariables, refetch])

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    push(`package-categories?${urlParams}`)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    push(`package-categories?${urlParams}`)
    setSearch(value)
  }

  return (
    <MainContainer>
      <Helmet>
        <title>Package Categories</title>
      </Helmet>
      <ToolbarHeader title="Package Categories">
        <span className={style.dropdownLabel}>Sort by:</span>
        <Dropdown
          selection
          className={style.searchDropdown}
          value={sortBy}
          options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
            text: SORT_BY_OPTIONS[option].text,
            value: SORT_BY_OPTIONS[option].key,
            key: SORT_BY_OPTIONS[option].key,
          }))}
          onChange={handleSortChange}
          data-test="sort-dropdown"
        />

        <Search
          className={style.search}
          placeholder="Search Package Categories"
          showNoResults={false}
          searchText={search}
          onSearchChange={handleSearchChange}
        />

        <Button
          data-test="button-new-category"
          onClick={() => push(PACKAGE_CATEGORIES.new)}
        >
          New Category
        </Button>
      </ToolbarHeader>

      <PackageCategoriesList
        data={
          packageCategoriesData && packageCategoriesData.listPackageCategories
        }
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
      />
    </MainContainer>
  )
}
