import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Grid,
  Button,
  TransferList,
  LoadingModal,
  useForm,
  useDisclosure,
} from '@labsavvyapp/components'
import { GET_KIT_LIST } from '../../../../graphql/kits/queries.js'
import { useMutation, useQuery } from 'react-apollo'
import styled from '@emotion/styled'
import { ListPartnerProjectProviders } from '../../../../graphql/labs/queries.js'
import { useHistory, useParams } from 'react-router'
import { UpdatePartnerProject } from '../../../../graphql/partner/mutations.js'
import {
  GetPartnerProjectKits,
  GetPartnerProjectSettings,
} from '../../../../graphql/partner/queries.js'

const AddKitTabLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 900px;
  padding: 30px 20px;
`
export default function AddKitTab() {
  const history = useHistory()
  const { partnerId, projectId } = useParams()

  const form = useForm({
    initialValues: { kits: [[], []] },
  })
  const [
    updatePartnerProject,
    { loading: updatePartnerProjectLoading, error: updatePartnerProjectError },
  ] = useMutation(UpdatePartnerProject)
  const [
    isSavingModalOpen,
    { open: openSavingModal, close: closeSavingModal },
  ] = useDisclosure(false)

  const [kitSearch, setKitSearch] = useState(['', ''])

  const { data: getKitListResponse, loading: kitListLoading } = useQuery(
    GET_KIT_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  )

  const { data: getLabListResponse, loading: labListLoading } = useQuery(
    ListPartnerProjectProviders,
    {
      variables: {
        partnerId,
        projectId,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  )

  const { data: projectKitsData, loading: projectKitsLoading } = useQuery(
    GetPartnerProjectSettings,
    {
      variables: { partnerId, projectId },
      notifyOnNetworkStatusChange: true,
    },
  )

  useEffect(() => {
    if (!kitListLoading && !labListLoading && !projectKitsLoading) {
      const kitList = getKitListResponse?.listKits?.kits
      const labList = _.map(
        getLabListResponse?.listPartnerProjectProviders,
        'provider._id',
      )

      const filteredAvailableKits = _.filter(kitList, (kit) =>
        labList.includes(kit.provider_id),
      )

      const availableKits = _.map(filteredAvailableKits, (kit) => ({
        value: kit._id,
        label: `${kit.name}`,
      }))

      const projectKits = _.map(
        projectKitsData?.getPartnerProject?.kits,
        'kit_id',
      )

      const selectedKits = _.filter(availableKits, (kit) =>
        projectKits.includes(kit.value),
      )

      const availableKitsFinal = _.filter(
        availableKits,
        (kit) => !projectKits.includes(kit.value),
      )

      const kitData = [availableKitsFinal, selectedKits]
      form.setFieldValue('kits', kitData)
    }
  }, [kitListLoading, labListLoading, projectKitsLoading])

  const handleAddKitToProject = async (values) => {
    openSavingModal()

    // process kit object
    const selectedKits = _.map(values.kits[1], (kit) => ({ kit_id: kit.value }))

    updatePartnerProject({
      variables: {
        partnerId,
        projectId,
        data: {
          kits: selectedKits,
        },
      },
      refetchQueries: [
        {
          query: GetPartnerProjectKits,
          variables: {
            partnerId,
            projectId,
          },
        },
      ],
    })
    setTimeout(() => {
      history.goBack()
      closeSavingModal()
    }, 3000)
  }

  return (
    <Grid>
      <form onSubmit={form.onSubmit((values) => handleAddKitToProject(values))}>
        <Grid.Col>
          <AddKitTabLayout>
            <TransferList
              searchPlaceholder="Search..."
              nothingFound="Nothing here"
              listHeight={'450px'}
              searchValues={kitSearch}
              onSearch={setKitSearch}
              titles={['Available Kits', 'Selected Kits associated to Project']}
              {...form.getInputProps('kits')}
            />
          </AddKitTabLayout>
        </Grid.Col>

        <Grid.Col>
          <Button
            type="submit"
            radius="xl"
            size="md"
            uppercase
            variant="filled"
          >
            Save Changes
          </Button>
        </Grid.Col>
      </form>

      <LoadingModal
        loadingMessage="We are saving your changes... please wait"
        successMessage="Your data has been saved"
        errorMessage="Something went wrong. Please try again later."
        isLoading={updatePartnerProjectLoading}
        opened={isSavingModalOpen}
        onClose={closeSavingModal}
        hasError={updatePartnerProjectError}
      />
    </Grid>
  )
}
