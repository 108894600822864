import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'
import MainContainer from '../../components/MainContainer/MainContainer'
import { useQuery } from 'react-apollo'
import { ListProviders } from '../../graphql/providers/queries'
import {
  StyledCompendiaDropdown,
  StyledCompendiaSearch,
} from './CompendiaPage.styles'
import { Button, ToolbarHeader } from '@labsavvyapp/components'

import { GET_COMPENDIUM_PANELS_AND_TESTS } from '../../graphql/compendia/queries'
import CompendiaList from './CompendiaList/CompendiaList'

export default function CompendiaPage() {
  const [providers, setProviders] = useState()
  const [userSelectedProvider, setUserSelectedProvider] = useState()
  const [compendiaSearchKey, setCompendiaSearchKey] = useState('')

  const { data: providerList } = useQuery(ListProviders, {
    variables: {},
  })

  useEffect(() => {
    if (providerList?.listProviders && !providers) {
      setProviders(providerList.listProviders.providers)
      setUserSelectedProvider(providerList.listProviders.providers[0])
    }
  }, [providerList, providers])

  const handleSetProvider = async (event, result) => {
    const { value } = result || event.target
    let provider = _.find(providers, { _id: value })
    setUserSelectedProvider(provider)
  }

  const {
    data: compendiumTestsResponse,
    fetchMore,
    loading,
    networkStatus,
  } = useQuery(GET_COMPENDIUM_PANELS_AND_TESTS, {
    skip: !userSelectedProvider?._id,
    variables: {
      providerId: userSelectedProvider?._id,
      search: compendiaSearchKey,
      type: 'group',
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <MainContainer>
      <Helmet>
        <title>Compendia</title>
      </Helmet>

      <ToolbarHeader title="Compendia">
        <span>Compendium:</span>
        {providers && (
          <StyledCompendiaDropdown
            selection
            value={userSelectedProvider._id}
            options={providers.map((item) => ({
              text: item.name,
              value: item._id,
              key: item._id,
            }))}
            onChange={handleSetProvider}
          />
        )}
        <StyledCompendiaSearch
          placeholder="Search Compendia"
          showNoResults={false}
          searchText={compendiaSearchKey}
          onSearchChange={setCompendiaSearchKey}
        />
        <Button
          radius="xl"
          size="md"
          uppercase
          variant="filled"
          onClick={() => alert('Add Compendia functionality pending')}
        >
          Add Compendia
        </Button>
      </ToolbarHeader>
      <CompendiaList
        data={compendiumTestsResponse?.listCompendiumPanels}
        loading={networkStatus === 1 || networkStatus === 3 || loading}
        fetchMore={fetchMore}
        searchKey={compendiaSearchKey}
        userSelectedProvider={userSelectedProvider}
      />
    </MainContainer>
  )
}
