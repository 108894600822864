import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { EyeIcon, EyeClosedIcon, ArrowDown, ArrowUp } from './icons'

const CardLayout = styled.div`
  border: 1px solid #f7f6f6;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
  background-color: #f7f6f6;
  font-family: Avenir;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: 520px;
`

const CardFieldLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
  align-items: center;
`

const CardButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  svg {
    fill: ${(props) => props.disabled && 'red'};
  }
`

const CardInput = styled.input`
  max-width: 200px;
  font-size: 12px;
  line-height: 5px;
  font-family: Avenir;
`

export const Card = ({
  columnName,
  columnWidth,
  isActive,
  columnKey,
  index,
  handleMoveCard,
  editCardList,
  isFirstItem,
  isLastItem,
}) => {
  const [userEditColumnName, setUserEditColumnName] = useState(columnName)
  const [userEditColumnWidth, setUserEditColumnWidth] = useState(columnWidth)
  const [userEditIsActive, setUserEditIsActive] = useState(isActive)

  const handleEditCardField = (event) => {
    const { name, value } = event.target

    if (name === 'columnName') {
      setUserEditColumnName(value)
    } else if (name === 'columnWidth') {
      setUserEditColumnWidth(value)
    } else if (name === 'isActive') {
      setUserEditIsActive(value)
    }
  }

  useEffect(() => {
    editCardList(
      userEditColumnName,
      userEditColumnWidth,
      userEditIsActive,
      columnKey,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEditColumnName, userEditColumnWidth, userEditIsActive])

  return (
    <CardLayout>
      <CardFieldLayout>
        <CardButton
          onClick={() => handleMoveCard(index, index - 1)}
          visible={!isFirstItem}
          disabled={!userEditIsActive}
        >
          <ArrowUp />
        </CardButton>

        <CardButton
          onClick={() => handleMoveCard(index, index + 1)}
          visible={!isLastItem}
          disabled={!userEditIsActive}
        >
          <ArrowDown />
        </CardButton>

        <CardInput
          name="columnName"
          value={userEditColumnName}
          onChange={handleEditCardField}
          disabled={!userEditIsActive}
        />
      </CardFieldLayout>

      <CardFieldLayout>
        <div>
          <CardInput
            name="columnWidth"
            value={userEditColumnWidth}
            onChange={(event) => {
              let parsedNumber = parseFloat(event.target.value)
              if (parsedNumber % 1 === 0 || isNaN(parsedNumber)) {
                event.target.value = Math.abs(parsedNumber)
              } else {
                let formattedNumber = parsedNumber
                  .toFixed(2)
                  .replace(/\.?0+$/, '')
                event.target.value = Math.abs(formattedNumber)
              }
              handleEditCardField(event)
            }}
            type="number"
            disabled={!userEditIsActive}
          />
        </div>
      </CardFieldLayout>
      <CardFieldLayout>
        <CardButton
          onClick={() =>
            handleEditCardField({
              target: { name: 'isActive', value: !userEditIsActive },
            })
          }
          visible
          disabled={false}
        >
          {!userEditIsActive ? <EyeClosedIcon /> : <EyeIcon />}
        </CardButton>
      </CardFieldLayout>
    </CardLayout>
  )
}
