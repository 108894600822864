import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Dropdown } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import { camelizeKeys } from 'humps'

import { ListPartners } from '../../../graphql/partner/queries.js'
import { ToolbarHeader } from '@labsavvyapp/components'
import { Button, Search } from '@labsavvyapp/ui-components'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { PARTNERS } from '../../../config/routes'
import { SORT_BY_OPTIONS } from './constants'
// import ActionIconsMenu from '../ActionIconsMenu/ActionIconsMenu'
import PartnersList from './PartnersList'
import { updateURLParameter } from '../../../utils/urls'
import style from './ListPage.module.css'

function getQueryVariables(sortBy, search) {
  const variables = {
    sort: {},
    filter: {},
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.nameAZ.key:
      variables.sort.name = SORT_BY_OPTIONS.nameAZ.value
      break
    case SORT_BY_OPTIONS.nameZA.key:
      variables.sort.name = SORT_BY_OPTIONS.nameZA.value
      break
    default:
      break
  }

  if (search) {
    variables.filter = { name: search }
  }

  return variables
}

export default function ListPage() {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()

  const query = new URLSearchParams(locationSearch)

  const [sortBy, setSortBy] = useState(
    query.get('sort') || SORT_BY_OPTIONS.nameAZ.key,
  )
  const [search, setSearch] = useState(query.get('search') || '')
  const { data, fetchMore, refetch, networkStatus } = useQuery(ListPartners, {
    variables: getQueryVariables(sortBy, search),
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    refetch({
      ...getQueryVariables(sortBy, search),
      page: 1,
    })
  }, [sortBy, search, refetch])

  function handleSortChange(_, { value }) {
    const urlParams = updateURLParameter('sort', value)
    push(`?${urlParams}`)
    setSortBy(value)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    push(`?${urlParams}`)
    setSearch(value)
  }

  return (
    <MainContainer>
      <ToolbarHeader title="Partners">
        <span className={style.dropdownLabel}>Sort by:</span>
        <Dropdown
          selection
          className={style.dropdown}
          value={sortBy || SORT_BY_OPTIONS.role}
          options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
            text: SORT_BY_OPTIONS[option].text,
            value: SORT_BY_OPTIONS[option].key,
            key: SORT_BY_OPTIONS[option].key,
          }))}
          onChange={handleSortChange}
          data-test="sort-dropdown"
        />

        <Search
          className={style.search}
          placeholder="Search Partners"
          showNoResults={false}
          searchText={search}
          onSearchChange={handleSearchChange}
        />

        <Button onClick={() => push(PARTNERS.new)}>New Partner</Button>
      </ToolbarHeader>

      <div className={style.contentContainer}>
        {/* <ActionIconsMenu /> */}
        <PartnersList
          data={data && camelizeKeys(data)}
          fetchMore={fetchMore}
          loading={networkStatus === 1 || networkStatus === 3}
        />
      </div>
    </MainContainer>
  )
}
