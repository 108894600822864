import React from 'react'

import classnames from 'classnames'
import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  Card,
} from '@labsavvyapp/ui-components'
import {
  RefreshButton,
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/components'

import style from './TeamMembersList.module.css'
import TeamMembersListRow from './TeamMembersListRow'

export default function TeamMembersList({
  data = { admin_team_members: [] },
  fetchMore,
  loading,
}) {
  const { page, pages, total, admin_team_members } = data

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Name',
            className: classnames(style.column, style.imageNameColumn),
          },
          { name: 'Email/ Username', className: style.column },
          {
            name: 'Role',
            className: style.column,
          },
          {
            name: 'Joined',
            className: style.column,
          },
          {
            name: 'Status',
            className: style.column,
          },
        ]}
      />
      <InfiniteScrollList
        dataLength={admin_team_members.length}
        scrollableTarget="team-members-list"
        hasMore={hasMore({ page, pages })}
        next={() =>
          fetchNext('listUsers', 'admin_team_members', {
            page,
            fetchMore,
          })
        }
      >
        {!loading && isEmpty(admin_team_members) && (
          <Card data-test="EmptyCard" emptyCard>
            <b>No team members were found. </b> If you feel this is an error,
            you may try to <RefreshButton />
            &mdash; this may be a momentary issue.
          </Card>
        )}

        {!isEmpty(admin_team_members) && (
          <TeamMembersListRow items={admin_team_members} />
        )}

        <ListLoader
          fetched={admin_team_members.length}
          total={total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
