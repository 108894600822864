import React from 'react'
import { Switch, Route } from 'react-router'

import { COMPENDIA } from '../config/routes'
import MainLayout from '../components/MainLayout/MainLayout'
import CompendiaPage from '../pages/Compendia/CompendiaPage'
import ManageCompendiaPage from '../pages/Compendia/ManageCompendiaPage/ManageCompendiaPage'

export default function CompendiaRoutes({ logged, user }) {
  return (
    <MainLayout noPadding logged={logged} user={user}>
      <Switch>
        <Route exact path={COMPENDIA.base} component={CompendiaPage} />
        <Route exact path={COMPENDIA.new} component={CompendiaPage} />
        <Route exact path={COMPENDIA.manage} component={ManageCompendiaPage} />
        <Route exact path={COMPENDIA.view} component={ManageCompendiaPage} />
      </Switch>
    </MainLayout>
  )
}
