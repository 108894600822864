import React from 'react'
import _ from 'lodash'

import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  ListRow,
  Card,
} from '@labsavvyapp/ui-components'
import { Skeleton, RefreshButton } from '@labsavvyapp/components'
import {
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'
import { COMPENDIA } from '../../../config/routes'
import sharedStyle from '../../../styles/shared.module.css'
import style from './CompendiaList.module.css'
import classNames from 'classnames'
import { generatePath, useHistory } from 'react-router'

export default function CompendiaList({
  data = { packages: [] },
  loading,
  fetchMore,
  searchKey,
  userSelectedProvider,
}) {
  const { push } = useHistory()

  const { page, pages, total, compendium_panels } = data

  const filteredCompendiaData = _.filter(
    compendium_panels,
    (panel) =>
      panel.tests[0].order.code
        .toLowerCase()
        .includes(searchKey.toLowerCase()) ||
      panel.tests[0].order.name
        .toLowerCase()
        .includes(searchKey.toLowerCase()) ||
      panel.code.toLowerCase().includes(searchKey.toLowerCase()) ||
      panel.name.toLowerCase().includes(searchKey.toLowerCase()),
  )

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Panel Code',
            className: style.columnCode,
          },
          {
            name: 'Panel Name',
            className: style.column,
          },
          {
            name: '# of Results',
            className: style.column,
          },
        ]}
      />

      {loading && (
        <ListRow
          data-test="package-list-row"
          className={classNames(sharedStyle.listRow, style.row)}
          onClick={() => push(`${COMPENDIA.base}`)}
        >
          <div className={style.columnCode}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />
          </div>
          <div className={style.column}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />{' '}
          </div>
          <div className={style.column}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />{' '}
          </div>
        </ListRow>
      )}

      {compendium_panels && (
        <InfiniteScrollList
          dataLength={compendium_panels?.length}
          scrollableTarget="package-list"
          hasMore={hasMore({ page, pages })}
          next={() =>
            fetchNext('listCompendiumPanels', 'compendium_panels', {
              page,
              fetchMore,
            })
          }
        >
          {!loading && isEmpty(compendium_panels) && (
            <Card
              data-test="empty-card"
              emptyCard
              className={style.notFoundMessage}
            >
              <b>No compendia items were found. </b> If you feel this is an
              error, you may try to <RefreshButton /> &mdash; this may be a
              momentary issue.
            </Card>
          )}

          {filteredCompendiaData.map((compendiumData) => (
            <ListRow
              data-test="package-list-row"
              key={`${compendiumData.code.toLowerCase()}-${compendiumData.name.toLowerCase()}`}
              className={classNames(sharedStyle.listRow, style.row)}
              onClick={() =>
                push(
                  generatePath(COMPENDIA.view, {
                    laboratoryId: userSelectedProvider._id,
                    id: compendiumData.code,
                    name: compendiumData.name,
                  }),
                )
              }
            >
              <div className={style.columnCode}>{compendiumData.code}</div>
              <div className={style.column}>{compendiumData.name}</div>
              <div className={style.column}>
                {compendiumData.tests.length} results
              </div>
            </ListRow>
          ))}

          <ListLoader
            fetched={compendium_panels.length}
            total={total}
            loading={loading}
          />
        </InfiniteScrollList>
      )}
    </List>
  )
}
