import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GetAllConfigs } from '../../../graphql/settings/queries'
// import style from './ConfigurationPage.module.css'
// import AddEditConfigurationPage from './AddEditConfigurationPage'
import ConfigurationRow from './ConfigurationRow'

export default function ConfigurationPage() {
  const { data: configData } = useQuery(GetAllConfigs, {
    fetchPolicy: 'network-only',
  })

  return (
    <div style={{ width: '100%', flexDirection: 'row', display: 'flex' }}>
      <div style={{ width: '100%' }}>
        {configData &&
          configData.getAllConfig.map((config, key) => (
            <ConfigurationRow data={config} key={key} />
          ))}
      </div>
    </div>
  )
}
