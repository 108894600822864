import { gql } from '@apollo/client'

export const GetAllConfigs = gql`
  query GetAllConfigs {
    getAllConfig {
      _id
      domain
      subdomain
      category
      subcategory
      code
      subcode
      name
      description
      placeholder
      value
      value2
      value3
      created_at
      updated_at
      deleted_at
    }
  }
`

export const LIST_CONFIGS = gql`
  query ListConfigs(
    $limit: PositiveInt = 100
    $page: NonNegativeInt = 1
    $filter: ConfigsFilter
    $sort: ConfigsSort
  ) {
    listConfigs(limit: $limit, page: $page, filter: $filter, sort: $sort) {
      configs {
        _id
        domain
        subdomain
        category
        subcategory
        code
        subcode
        name
        description
        placeholder
        value
        value2
        value3
        created_at
        updated_at
        deleted_at
      }
    }
  }
`
