import React from 'react'

import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  Card,
} from '@labsavvyapp/ui-components'
import {
  RefreshButton,
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/components'

import style from './PackageCategoriesList.module.css'
import PackageCategoriesListRow from './PackageCategoriesListRow'

export default function PackageCategoriesList({
  data = { packageCategories: [] },
  fetchMore,
  loading,
}) {
  const { page, pages, total, packageCategories } = data

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Icon',
            className: style.iconColumn,
          },
          {
            name: 'Name',
            className: style.column,
          },
          {
            name: 'Completed',
            className: style.column,
          },
        ]}
      />
      <InfiniteScrollList
        dataLength={packageCategories.length}
        scrollableTarget="package-categories-list"
        hasMore={hasMore({ page, pages })}
        next={() =>
          fetchNext('listPackageCategories', 'package_categories', {
            page,
            fetchMore,
          })
        }
      >
        {!loading && isEmpty(packageCategories) && (
          <Card
            data-test="empty-card"
            emptyCard
            className={style.notFoundMessage}
          >
            <b>No Package Categories were found. </b> If you feel this is an
            error, you may try to <RefreshButton />
            &mdash; this may be a momentary issue.
          </Card>
        )}

        {!isEmpty(packageCategories) && (
          <PackageCategoriesListRow items={packageCategories} />
        )}

        <ListLoader
          fetched={packageCategories.length}
          total={total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
