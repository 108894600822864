import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { generatePath, useHistory, useParams } from 'react-router'
import { Grid } from 'semantic-ui-react'

import {
  Form,
  InputField,
  SubmitButton,
  useSavingModals,
} from '@labsavvyapp/ui-components'

import { GetPartnerProfileTab } from '../../../../graphql/partner/queries.js'
import {
  CreatePartner,
  UpdatePartner,
} from '../../../../graphql/partner/mutations.js'
import { PARTNERS } from '../../../../config/routes'
import sharedStyles from '../../../../styles/shared.module.css'

export default function ProfileTab() {
  const { push } = useHistory()
  const { partnerId } = useParams()
  const isEditing = Boolean(partnerId)

  const showModalsCallback = async ({
    account_name,
    custom_package_prefix,
  }) => {
    if (isEditing) {
      await updatePartner({
        variables: {
          id: partnerId,
          data: {
            name: account_name,
            custom_package_prefix: custom_package_prefix,
          },
        },
      })
    } else {
      await createPartner({
        variables: {
          data: {
            name: account_name,
            custom_package_prefix: custom_package_prefix,
          },
        },
      })
    }
  }

  // Modals
  const [modals, { showModals = true }] = useSavingModals({
    savingMessage: "We're saving the partner, please wait...",
    savedMessage: 'The partner has been saved.',
    callback: showModalsCallback,
  })

  const { data: partnerData } = useQuery(GetPartnerProfileTab, {
    variables: {
      id: partnerId,
    },
    skip: !partnerId,
  })

  const [createPartner] = useMutation(CreatePartner, {
    async onCompleted({ createPartner }) {
      push(
        generatePath(PARTNERS.admin.subSection, {
          partnerId: createPartner._id,
          section: 'general',
          subSection: 'profile',
        }),
      )
    },
  })
  const [updatePartner] = useMutation(UpdatePartner, {
    refetchQueries: [
      {
        query: GetPartnerProfileTab,
        variables: {
          id: partnerId,
        },
      },
    ],
  })

  const [accountName, setAccountName] = useState()
  const [customPackagePrefix, setCustomPackagePrefix] = useState()

  useEffect(() => {
    setAccountName(partnerData?.getPartner.name)
    setCustomPackagePrefix(partnerData?.getPartner.custom_package_prefix)
  }, [partnerData])

  const isDirty = () => {
    if (accountName && customPackagePrefix) {
      return Boolean(
        !/^\s*$/.test(accountName) && !/^\s*$/.test(customPackagePrefix),
      )
    } else return false
  }

  const parseFormInputData = (partnerData) => ({
    account_name: partnerData.getPartner.name,
    custom_package_prefix: partnerData.getPartner.custom_package_prefix,
  })

  return (
    <>
      {/* Modals */}
      {modals}

      {/* Form */}
      <Form
        initialFormData={partnerData && parseFormInputData(partnerData)}
        onSubmit={showModals}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="account_name"
                label="Account Name"
                onChange={setAccountName}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={8} tablet={16}>
              <InputField
                name="custom_package_prefix"
                label="Custom Package Prefix"
                onChange={setCustomPackagePrefix}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <SubmitButton
                className={sharedStyles.marginTop10}
                disabled={!isDirty()}
              >
                Save Changes
              </SubmitButton>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </>
  )
}
