import React, { useState } from 'react'
import { Button } from '@labsavvyapp/ui-components'
import { Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

export default function MaintenanceRow({ data }) {
  const [selectedItemFromDropdown, setSelectedItemFromDropdown] = useState(
    data?.data && data?.data[0],
  )

  const handleDropdownSelection = async (event, result) => {
    const { value } = result || event.target
    let selected = _.find(data.data, { _id: value })
    setSelectedItemFromDropdown(selected)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid #ECECEB',
      }}
      key={data.index}
    >
      <div style={{ width: '50%', flexDirection: 'column' }}>
        <div style={{ fontWeight: 600, fontSize: 16 }}>{data.title} </div>
        <div style={{ fontWeight: 200, fontSize: 14 }}>{data.subtitle}</div>
      </div>

      <div style={{ width: '30%', textAlign: 'right', paddingRight: 30 }}>
        <span style={{ fontSize: 12, marginRight: 10 }}>
          {data.dataName && data.dataName + ' :'}
        </span>
        {data?.data ? (
          <Dropdown
            selection
            options={data?.data?.map((item) => ({
              text: item.name,
              value: item._id,
              key: item._id,
            }))}
            onChange={handleDropdownSelection}
            defaultValue={data?.data[0]?._id}
            search
          />
        ) : (
          <></>
        )}
      </div>

      <div style={{ width: '20%', textAlign: 'center', paddingRight: 30 }}>
        <Button
          onClick={() => data.action(selectedItemFromDropdown)}
          size="small"
        >
          Run
        </Button>
      </div>
    </div>
  )
}
