import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import { Input } from 'semantic-ui-react'

import classnames from 'classnames'
import { ListRow, Form } from '@labsavvyapp/ui-components'

import { SavePartnerPricingTest } from '../../../../../graphql/partner-pricing-list/mutations.js'

import sharedStyle from '../../../../../styles/shared.module.css'
import style from './PartnerPricingRow.module.css'

export default function PartnerPricingRow({ items, handleRefetch }) {
  const [savePartnerPricingTest, { loading }] = useMutation(
    SavePartnerPricingTest,
  )
  const [partnerPricingList, setPartnerPricingList] = useState([])

  const handleSavePartnerPricing = async (value, e) => {
    if (e.target) {
      value[e.target.name] = parseFloat(e.target.value)
      savePartnerPricingTest({
        variables: {
          input: {
            id: value.id,
            discount: value.discount || 0,
            adjusted_price_override: value.adjustedPrice || 0,
            retail_price: value.retailPrice || 0,
          },
        },
      })
    }
  }

  const handleSavePartnerPricingRetailPrice = async (value, e) => {
    if (e.target) {
      value[e.target.name] = parseFloat(e.target.value)
      savePartnerPricingTest({
        variables: {
          input: {
            id: value.id,
            discount: value.discount || 0,
            adjusted_price_override: value.adjustedPrice || 0,
            retail_price: value.retailPrice || 0,
          },
        },
      })
      handleRefetch()
    }
  }

  useEffect(() => {
    setPartnerPricingList(items)
  }, [items])

  return partnerPricingList.map((item, index) => (
    <Form key={item.id} initialFormData={item}>
      <ListRow
        key={item.id}
        className={classnames(sharedStyle.listRow, style.row)}
      >
        <div className={style.firstColumn}>{item.testCode}</div>
        <div className={style.secondColumn}>{item.testName}</div>
        <div className={style.formColumn}>
          <Input
            name="providerPrice"
            icon="dollar"
            iconPosition="left"
            type="number"
            defaultValue={items[index]?.providerPrice}
            style={{ cursor: 'default' }}
            disabled
          />
        </div>

        <div className={style.formColumn}>
          <Input
            name="lsPrice"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            defaultValue={items[index]?.lsPrice}
            disabled
            style={{ cursor: 'default' }}
          />
        </div>
        <div className={style.formColumn}>
          <Input
            name="standardRetailPrice"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            defaultValue={items[index]?.standardRetailPrice}
            style={{ cursor: 'default' }}
            disabled
          />
        </div>
        <div className={style.formColumn}>
          <Input
            name="adjustedPrice"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            defaultValue={items[index]?.adjustedPrice}
            onBlur={(e) => handleSavePartnerPricing(item, e)}
            required={true}
            disabled={loading}
          />
        </div>
        <div className={style.formColumn}>
          <Input
            name="retailPrice"
            icon="dollar"
            iconPosition="left"
            type="number"
            placeholder={0}
            defaultValue={items[index]?.retailPrice}
            onBlur={(e) => handleSavePartnerPricingRetailPrice(item, e)}
            required={true}
            disabled={loading}
          />
        </div>
      </ListRow>
    </Form>
  ))
}
