import gql from 'graphql-tag'

export const CREATE_KIT = gql`
  mutation CreateKit($data: KitInput!) {
    createKit(data: $data) {
      _id
      name
      description
      short_name
      sku_kit_id
      sku_lab_id
      code
      category
      brand
      fulfillment
      provider_id
      compendia_codes
      meta {
        code
        value
      }
    }
  }
`

export const UPDATE_KIT = gql`
  mutation UpdateKit($id: MongoID!, $data: KitInput!) {
    updateKit(id: $id, data: $data) {
      _id
      name
      description
      short_name
      sku_kit_id
      sku_lab_id
      code
      category
      brand
      fulfillment
      provider_id
      compendia_codes
      meta {
        code
        value
      }
    }
  }
`

export const DELETE_KIT = gql`
  mutation DeleteKit($id: MongoID!) {
    deleteKit(id: $id) {
      _id
      name
      description
      short_name
      sku_kit_id
      sku_lab_id
      code
      category
      brand
      fulfillment
      provider_id
      compendia_codes
      meta {
        code
        value
      }
    }
  }
`
