export const DEFAULT_COLUMNS = [
  {
    column_name: 'Patient Name',
    column_width: null,
    column_key: 'patient_name',
    is_active: true,
  },
  {
    column_name: 'DOB',
    column_width: null,
    column_key: 'dob',
    is_active: true,
  },
  {
    column_name: 'Accession #',
    column_width: null,
    column_key: 'filler_id',
    is_active: true,
  },
  {
    column_name: 'Order ID',
    column_width: null,
    column_key: 'short_id',
    is_active: true,
  },
  {
    column_name: 'Report Name',
    column_width: null,
    column_key: 'name',
    is_active: true,
  },
  {
    column_name: 'Provider Name',
    column_width: null,
    column_key: 'provider_name',
    is_active: true,
  },
  {
    column_name: 'Ordered Date',
    column_width: null,
    column_key: 'ordered_date',
    is_active: true,
  },
  {
    column_name: 'Report Date',
    column_width: null,
    column_key: 'report_date',
    is_active: true,
  },
  {
    column_name: 'Status',
    column_width: null,
    column_key: 'status',
    is_active: true,
  },
  {
    column_name: 'Reviewed',
    column_width: null,
    column_key: 'reviewed',
    is_active: true,
  },
  {
    column_name: 'Viewed',
    column_width: null,
    column_key: 'viewed',
    is_active: true,
  },
  {
    column_name: 'Trending',
    column_width: null,
    column_key: 'trending',
    is_active: true,
  },
  {
    column_name: 'Lab Report PDF',
    column_width: null,
    column_key: 'lab_report_pdf',
    is_active: true,
  },
  {
    column_name: 'Place Order',
    column_width: null,
    column_key: 'place_order',
    is_active: true,
  },
]
