import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useHistory, useParams } from 'react-router'
import { useQuery } from '@apollo/react-hooks'
import { Search, Button } from '@labsavvyapp/ui-components'
import style from './KitsTab.module.css'
import { SORT_BY_OPTIONS } from './constants.js'

//Queries and Mutations
import { ListPartnerProjectProviders } from '../../../../graphql/labs/queries.js'
import {
  GetPartnerProjectKits,
  GetPartnerProjectSettings,
} from '../../../../graphql/partner/queries.js'
import KitsList from './KitsList/KitsList.js'
import { GET_KIT_LIST } from '../../../../graphql/kits/queries'

function getQueryVariables(sortBy, search) {
  const variables = {
    limit: 30,
    sort: {},
    filter: {},
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.nameAZ.key:
      variables.sort.name = SORT_BY_OPTIONS.nameAZ.value
      break
    case SORT_BY_OPTIONS.nameZA.key:
      variables.sort.name = SORT_BY_OPTIONS.nameZA.value
      break
    default:
      break
  }

  if (search) {
    variables.filter = { name: search }
  }

  return variables
}

export default function LabsTab() {
  const { push } = useHistory()
  const { partnerId, projectId } = useParams()

  const query = new URLSearchParams(window.location.search)
  const [sortBy] = useState(query.get('sort') || SORT_BY_OPTIONS.nameAZ.key)
  const [search, setSearch] = useState(query.get('search') || '')

  // Get Default Lab/Provider
  const { data: projectSettingsData, refetch: refetchProjectSettings } =
    useQuery(GetPartnerProjectSettings, {
      variables: { partnerId, projectId },
      notifyOnNetworkStatusChange: true,
    })

  // Fetch packages list
  const {
    data: getLabListResponse,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery(ListPartnerProjectProviders, {
    variables: {
      partnerId,
      projectId,
      ...getQueryVariables(sortBy, search),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const { data: kitsData } = useQuery(GetPartnerProjectKits, {
    variables: {
      partnerId,
      projectId,
      ...getQueryVariables(sortBy, search),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const { data: kitListData } = useQuery(GET_KIT_LIST, {
    notifyOnNetworkStatusChange: true,
  })

  const refetchProjectSettingsData = () => {
    refetchProjectSettings({
      partnerId,
      projectId,
    })
  }
  // Refetch on mount
  useEffect(() => {
    refetchProjectSettingsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Refetch when filters change
  useEffect(() => {
    refetch({
      partnerId,
      projectId,
      page: 1,
      ...getQueryVariables(sortBy, search),
    })
  }, [sortBy, search, partnerId, projectId, refetch])

  /**
   * Constructs the URL by replacing the partnerId and section with the
   * current tab, and appends the search and sort parameters.
   */

  function handleSearchChange(value) {
    setSearch(value)
  }

  // Delete lab project
  // const [deletePartnerProjectProvider] = useMutation(
  //   DeletePartnerProjectProvider,
  // )

  // Set default lab in project

  const labList = _.map(
    getLabListResponse?.listPartnerProjectProviders,
    'provider',
  )
  const kitList = _.filter(
    kitListData?.listKits?.kits,
    (kit) => kit.provider_id,
  )
  const selectedKitList = _.map(kitsData?.getPartnerProject?.kits, 'kit_id')
  const selectedKitListData = _.filter(kitList, (kit) =>
    selectedKitList.includes(kit._id),
  )

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <Search
          className={style.search}
          placeholder="Search Lab"
          showNoResults={false}
          searchText={search}
          onSearchChange={handleSearchChange}
        />

        <Button
          onClick={() => push('kits/add-kit')}
          data-test="button-add-package"
          size={Button.size.small}
        >
          Manage Kits
        </Button>
      </div>

      <KitsList
        data={selectedKitListData}
        labList={labList}
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
      />
    </div>
  )
}
