import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListRow,
  Card,
} from '@labsavvyapp/ui-components'
import {
  Button,
  RefreshButton,
  ToolbarHeader,
  LoadingOverlay,
  isEmpty,
  useDisclosure,
} from '@labsavvyapp/components'
import classNames from 'classnames'

import sharedStyle from '../../../styles/shared.module.css'
import style from './ManageCompendiaPage.module.css'
import { useHistory, useParams } from 'react-router'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { useQuery } from 'react-apollo'
import { GET_COMPENDIUM_PANELS_AND_TESTS } from '../../../graphql/compendia/queries'
import { COMPENDIA } from '../../../config/routes'
import { StyledCompendiaSearch } from '../CompendiaPage.styles'

export default function ManageCompendiaPage() {
  const { push } = useHistory()
  const { laboratoryId, id, name } = useParams()
  const [resultSearchKey, setResultSearchKey] = useState()
  const [
    isLoadingOverlayVisible,
    { open: openLoadingOverlay, close: closeLoadingOverlay },
  ] = useDisclosure(false)

  const { data, loading } = useQuery(GET_COMPENDIUM_PANELS_AND_TESTS, {
    skip: !laboratoryId && !id,
    variables: {
      providerId: laboratoryId,
      search: id,
      type: 'group',
    },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (loading) {
      openLoadingOverlay()
    } else {
      closeLoadingOverlay()
    }
  }, [loading])

  const filteredPanel = _.filter(
    data?.listCompendiumPanels?.compendium_panels,
    (panel) =>
      panel.name.toLowerCase().includes(name?.toLowerCase()) &&
      panel.code.toLowerCase().includes(id?.toLowerCase()),
  )
  const selectedPanel = filteredPanel?.[0]
  const selectedPanelTests = selectedPanel?.tests

  const filteredPanelTests = _.filter(
    selectedPanelTests,
    (test) =>
      test.result.name.toLowerCase().includes(resultSearchKey?.toLowerCase()) ||
      test.result.code.toLowerCase().includes(resultSearchKey?.toLowerCase()),
  )

  const titleHeader = `${selectedPanel?.code ?? ''} - ${
    selectedPanel?.name ?? ''
  }`

  return (
    <MainContainer>
      <ToolbarHeader
        title={titleHeader}
        backButtonAction={() => push(COMPENDIA.base)}
        backButtonText="Back to Compendia List"
      >
        <StyledCompendiaSearch
          placeholder="Search Result"
          showNoResults={false}
          searchText={resultSearchKey}
          onSearchChange={setResultSearchKey}
        />
        <Button
          radius="xl"
          size="md"
          uppercase
          variant="filled"
          onClick={() => alert('Add Result functionality pending')}
        >
          Add Result
        </Button>
      </ToolbarHeader>
      <List>
        <ListHeader
          className={style.header}
          columns={[
            {
              name: 'Result Code',
              className: style.columnCode,
            },
            {
              name: 'Result Name',
              className: style.columnName,
            },
          ]}
        />

        {filteredPanelTests && (
          <InfiniteScrollList
            dataLength={filteredPanelTests.length}
            scrollableTarget="package-list"
          >
            {isEmpty(filteredPanelTests) && (
              <Card
                data-test="empty-card"
                emptyCard
                className={style.notFoundMessage}
              >
                <b>No results under this order were found. </b> If you feel this
                is an error, you may try to <RefreshButton />
                &mdash; this may be a momentary issue.
              </Card>
            )}
            {!isEmpty(filteredPanelTests) &&
              filteredPanelTests.map((result) => (
                <ListRow
                  data-test="package-list-row"
                  key={result._id}
                  className={classNames(sharedStyle.listRow, style.row)}
                >
                  <div className={style.columnCode}>{result?.result?.code}</div>
                  <div className={style.columnName}>{result?.result?.name}</div>
                </ListRow>
              ))}
          </InfiniteScrollList>
        )}
      </List>
      {isLoadingOverlayVisible && (
        <LoadingOverlay visible={isLoadingOverlayVisible} overlayBlur={2} />
      )}
    </MainContainer>
  )
}
