import React, { useEffect, useState } from 'react'
import { useSavingModals } from '@labsavvyapp/ui-components'
import { useQuery, useMutation } from 'react-apollo'

import MaintenanceRow from './MaintenanceRow'

// Queries and Mutations
import { ListPartners } from '../../../graphql/partner/queries'
import {
  GenerateMainPricings,
  GeneratePartnerPricings,
} from '../../../graphql/settings/mutations'

export default function MaintenancePage() {
  // Query handlers
  const { data: { listPartners: partners } = {} } = useQuery(ListPartners, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  // Mutation handlers
  const [statusMessage, setStatusMessage] = useState()
  const [generateMainPricings, { data: generateMainPricingsStatus }] =
    useMutation(GenerateMainPricings)
  const [generatePartnerPricings, { data: generatePartnerPricingsStatus }] =
    useMutation(GeneratePartnerPricings)

  // Modals
  const [modals, { showModals: startGenerateMainPricings }] = useSavingModals({
    savingMessage:
      "We're generating main pricing data for all partners. Please wait...",
    savedMessage: statusMessage,
    callback: async () => {
      await generateMainPricings()
    },
    onError: (error) => error,
  })

  const [partnerPricingModal, { showModals: startGeneratePartnerPricings }] =
    useSavingModals({
      savingMessage:
        "We're generating partner pricing data for this partner. Please wait...",
      savedMessage: statusMessage,
      callback: async ({ _id }) => {
        await generatePartnerPricings({
          variables: {
            partnerId: _id,
          },
        })
      },
      onError: (error) => error,
    })

  // Handle Modal success message
  useEffect(() => {
    setStatusMessage(generatePartnerPricingsStatus?.generatePartnerPricings)
  }, [generatePartnerPricingsStatus])

  useEffect(() => {
    setStatusMessage(generateMainPricingsStatus?.generateMainPricings)
  }, [generateMainPricingsStatus])

  // Menu Items with no data dependencies
  const [maintenanceItems, setMaintenanceItems] = useState([
    {
      title: 'Refresh all Main Pricing data',
      subtitle: 'This will cascade main pricing data for all partners.',
      action: startGenerateMainPricings,
      data: null,
    },
  ])

  // Rerender if data dependecies are loaded
  useEffect(() => {
    if (partners) {
      setMaintenanceItems((prevState) => [
        ...prevState,
        {
          title: 'Refresh Partner Pricing data for a specific partner',
          subtitle:
            'This will update partner pricing data for the selected partner. Partner Pricing generation is based on existing Main Pricing data',
          dataName: 'Partner',
          action: startGeneratePartnerPricings,
          data: partners.partners,
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners])

  return (
    <div>
      {maintenanceItems?.map((data, index) => {
        return <MaintenanceRow data={data} key={index} />
      })}
      {modals}
      {partnerPricingModal}
    </div>
  )
}
