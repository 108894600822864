import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

import { Dropdown } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import { Search } from '@labsavvyapp/ui-components'
import { camelizeKeys } from 'humps'
import _ from 'lodash'

import { GetPartnerPricing } from '../../../../graphql/partner-pricing-list/queries'
// import { Icon } from 'semantic-ui-react'
import PartnerPricingList from '../PartnerPricing/PartnerPricingList/PartnerPricingList'
import { updateURLParameter } from '../../../../utils/urls'
import { SORT_BY_OPTIONS, SEARCH_BY_OPTIONS } from './constants'
import style from './PartnerPricingTab.module.css'
import { PARTNERS } from '../../../../config/routes'
import { ListProviders } from '../../../../graphql/providers/queries'

const getQueryVariables = (sortBy, provider, partnerId, search, searchBy) => {
  const variables = {
    filter: {
      provider_id: provider?._id || '',
      search: search || '',
      search_by: searchBy || {
        by_test_code: SEARCH_BY_OPTIONS.by_test_code.value,
      },
      partner_id: partnerId || '',
    },
    sort: {},
    limit: 20,
  }

  switch (sortBy) {
    case SORT_BY_OPTIONS.by_test_code.key:
      variables.sort = {
        by_test_code: SORT_BY_OPTIONS.by_test_code.value,
      }
      break
    case SORT_BY_OPTIONS.by_test_name.key:
      variables.sort = {
        by_test_name: SORT_BY_OPTIONS.by_test_name.value,
      }
      break
    case SORT_BY_OPTIONS.by_partner_pricing.key:
      variables.sort = {
        by_partner_pricing: SORT_BY_OPTIONS.by_partner_pricing.value,
      }
      break
    case SORT_BY_OPTIONS.by_main_pricing.key:
      variables.sort = {
        by_main_pricing: SORT_BY_OPTIONS.by_main_pricing.value,
      }
      break
    // case SORT_BY_OPTIONS.by_override.key:
    //   variables.sort = { by_override: SORT_BY_OPTIONS.by_override.value }
    //   break
    // case SORT_BY_OPTIONS.by_price.key:
    //   variables.sort = { by_price: SORT_BY_OPTIONS.by_price.value }
    //   break
    default:
      break
  }

  switch (searchBy) {
    case SEARCH_BY_OPTIONS.by_test_code.key:
      variables.filter.search_by = {
        by_test_code: SEARCH_BY_OPTIONS.by_test_code.key,
      }
      break
    case SEARCH_BY_OPTIONS.by_test_name.key:
      variables.filter.search_by = {
        by_test_name: SEARCH_BY_OPTIONS.by_test_name.key,
      }
      break
    default:
      break
  }

  if (search) {
    variables.filter.search = search
  }

  return variables
}

export default function PartnerPricingTab() {
  const { push } = useHistory()
  const { search: locationSearch } = useLocation()
  const { partnerId, section } = useParams()
  const [provider, setProvider] = useState()
  const [providers, setProviders] = useState()
  const { data: providerList } = useQuery(ListProviders, {
    variables: {},
  })

  if (providerList?.listProviders && !providers) {
    setProviders(providerList.listProviders.providers)
    setProvider(providerList.listProviders.providers[0])
  }

  const query = new URLSearchParams(locationSearch)
  const [sortBy, setSortBy] = useState(SORT_BY_OPTIONS.by_partner_pricing.key)
  const [searchBy, setSearchBy] = useState(SORT_BY_OPTIONS.by_test_code.key)
  const [searchPlaceHolder, setSearchPlaceHolder] = useState(
    SORT_BY_OPTIONS.by_test_code.text,
  )
  const [search, setSearch] = useState(query.get('search') || '')
  const {
    data: partnerPricingListData,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery(GetPartnerPricing, {
    variables: {
      ...getQueryVariables(sortBy, provider, partnerId, search, searchBy),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  const navigateToURL = (params) => {
    const baseURL = PARTNERS.admin.section
      .replace(':partnerId', partnerId)
      .replace(':section', section)
    push(`${baseURL}?${params}`)
  }

  const handleSetProvider = async (event, result) => {
    const { value } = result || event.target
    let provider = _.find(providers, { _id: value })
    setProvider(provider)
  }

  const handleSortChange = (_, { value }) => {
    const urlParams = updateURLParameter('sort', value)
    navigateToURL(urlParams)
    setSortBy(value)
  }

  const handleSearchByChange = (_, { value }) => {
    setSearchBy(value)
    handleSearchChange(search)

    setSearchPlaceHolder(SORT_BY_OPTIONS[value].text)
  }

  const handleSearchChange = (search) => {
    const urlParams = updateURLParameter('search', search)
    push(`partner-pricing?${urlParams}`)
    setSearch(search)
  }

  // function handleExportCsv() {
  //   const items = partnerPricingListData.partnerPricing?.data
  //   let csv = ''

  //   // Loop the array of objects
  //   for (let row = 0; row < items.length; row++) {
  //     let keysAmount = Object.keys(items[row]).length
  //     let keysCounter = 0

  //     // If this is the first row, generate the headings
  //     if (row === 0) {
  //       // Loop each property of the object
  //       for (let key in items[row]) {
  //         // This is to not add a comma at the last cell
  //         // The '\r\n' adds a new line
  //         csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
  //         keysCounter++
  //       }
  //     } else {
  //       for (let key in items[row]) {
  //         csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
  //         keysCounter++
  //       }
  //     }

  //     keysCounter = 0
  //   }

  //   // Once we are done looping, download the .csv by creating a link
  //   csv = csv.replace('undefined', '')
  //   let link = document.createElement('a')
  //   link.id = 'download-csv'
  //   link.setAttribute(
  //     'href',
  //     'data:text/plain;charset=utf-8,' + encodeURIComponent(csv),
  //   )
  //   link.setAttribute('download', 'PartnerPricingList.csv')
  //   document.body.appendChild(link)
  //   document.querySelector('#download-csv').click()
  // }

  //refetch list on save data if sorting option is partner pricing
  const handleRefetch = () => {
    if (SORT_BY_OPTIONS.by_partner_pricing.value) {
      refetch({
        page: 1,
        ...getQueryVariables(sortBy, provider, partnerId, search, searchBy),
      })
    }
  }

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <div className={style.printAndSearchContainer}>
          {providers && (
            <>
              <div className={style.dropdownLabel}>Laboratory:</div>
              <Dropdown
                selection
                className={style.dropdown}
                defaultValue={provider?._id}
                options={providers.map((item) => ({
                  text: item.name,
                  value: item._id,
                  key: item._id,
                }))}
                onChange={handleSetProvider}
              />
            </>
          )}
          <>
            <div className={style.dropdownLabel}>Sort by:</div>
            <Dropdown
              selection
              className={style.dropdown}
              style={{ marginLeft: '1rem' }}
              value={sortBy}
              options={Object.keys(SORT_BY_OPTIONS).map((option) => ({
                text: SORT_BY_OPTIONS[option].text,
                value: SORT_BY_OPTIONS[option].key,
                key: SORT_BY_OPTIONS[option].key,
              }))}
              onChange={handleSortChange}
            />
          </>
          <>
            {/* <Button
              onClick={() => handleExportCsv()}
              variant="basic"
              className={style.printButton}
            >
              <Icon name="print" />
            </Button> */}
            <span className={style.dropdownLabel}>Search by:</span>
            <div style={{ width: '100' }}>
              <Dropdown
                selection
                value={searchBy}
                options={Object.keys(SEARCH_BY_OPTIONS).map((option) => ({
                  text: SEARCH_BY_OPTIONS[option].text,
                  value: SEARCH_BY_OPTIONS[option].key,
                  key: SEARCH_BY_OPTIONS[option].key,
                }))}
                onChange={handleSearchByChange}
                fluid
              />
            </div>

            <Search
              className={style.search}
              placeholder={`Search by ` + searchPlaceHolder}
              showNoResults={false}
              searchText={search}
              onSearchChange={handleSearchChange}
            />
          </>
        </div>
      </div>

      <PartnerPricingList
        data={camelizeKeys(partnerPricingListData)}
        loading={networkStatus === 1 || networkStatus === 3}
        fetchMore={fetchMore}
        handleRefetch={handleRefetch}
      />
    </div>
  )
}
