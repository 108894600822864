import gql from 'graphql-tag'

export const partnerContact = gql`
  fragment partnerContact on Contact {
    email
  }
`

export const partnerContactUserName = gql`
  fragment partnerContactUserName on UserName {
    first
    last
  }
`

export const partnerContactPhone = gql`
  fragment partnerContactPhone on Phone {
    number
  }
`

export const partnerName = gql`
  fragment partnerName on Partner {
    name
  }
`

export const customPackagePrefix = gql`
  fragment customPackagePrefix on Partner {
    custom_package_prefix
  }
`

export const partnerUploadedFile = gql`
  fragment partnerUploadedFile on UploadedFile {
    url
  }
`

export const GetPartner = gql`
  query GetPartner($id: MongoID!) {
    getPartner(id: $id) {
      ...partnerName
      logo {
        ...partnerUploadedFile
      }
      contact {
        name {
          ...partnerContactUserName
          display
        }
        phone1 {
          ...partnerContactPhone
        }
        ...partnerContact
      }
      created_at
      status
      ...customPackagePrefix
    }
  }

  ${partnerContact}
  ${partnerContactPhone}
  ${partnerContactUserName}
  ${partnerName}
  ${partnerUploadedFile}
  ${customPackagePrefix}
`

export const GetPartnerBranding = gql`
  query GetPartnerBranding($id: MongoID!) {
    getPartner(id: $id) {
      logo {
        ...partnerUploadedFile
      }
      branding {
        sidebar_color
        icon_selected
        button_background
        button_text
        text_link
      }
    }
  }
  ${partnerUploadedFile}
`

export const GetPartnerContactInfoTab = gql`
  query GetPartnerContactInfoTab($id: MongoID!) {
    getPartner(id: $id) {
      ...partnerName
      contact {
        name {
          ...partnerContactUserName
        }
        ...partnerContact
        company_url
        phone1 {
          ...partnerContactPhone
          type
        }
        phone2 {
          ...partnerContactPhone
          type
        }
        address {
          street
          city
          state
          zip
        }
      }
    }
  }

  ${partnerName}
  ${partnerContactUserName}
  ${partnerContact}
  ${partnerContactPhone}
`

export const GetPartnerProfileTab = gql`
  query GetPartnerProfileTab($id: MongoID!) {
    getPartner(id: $id) {
      ...partnerName
      ...customPackagePrefix
    }
  }

  ${partnerName}
  ${customPackagePrefix}
`

export const GetPartnerSettings = gql`
  query GetPartnerSettings($id: MongoID!) {
    getPartner(id: $id) {
      timezone
    }
  }
`

export const GetPartnerColumnSettings = gql`
  query GetPartnerColumnSettings($id: MongoID!) {
    getPartner(id: $id) {
      config {
        lab_report {
          enabled
          columns
        }
      }
    }
  }
`

export const GetPartnerInternalEventSettings = gql`
  query GetPartnerInternalEventSettings($id: MongoID!) {
    getPartner(id: $id) {
      hooks {
        internal {
          subscribers {
            event_code
            sub
            extra_payload
          }
        }
      }
    }
  }
`

export const GetPartnerWebhookEventSettings = gql`
  query GetPartnerWebhookEventSettings($id: MongoID!) {
    getPartner(id: $id) {
      hooks {
        webhook {
          subscribers {
            event_code
            url
            headers {
              code
              value
            }
            extra_payload
            auth {
              type
              username
              password
              token
            }
          }
        }
      }
    }
  }
`

export const GetPartnerProject = gql`
  query GetPartnerProject($partnerId: MongoID!, $projectId: MongoID!) {
    getPartnerProject(partner_id: $partnerId, project_id: $projectId) {
      name
      url_slug
      created_at
      status
      _id: _id
    }
  }
`

export const GetPartnerProjectKits = gql`
  query GetPartnerProjectKits($partnerId: MongoID!, $projectId: MongoID!) {
    getPartnerProject(partner_id: $partnerId, project_id: $projectId) {
      kits {
        kit_id
        ls_price
        retail_price
        ecommerce_price
      }
    }
  }
`

export const GetPartnerProjectProfile = gql`
  query GetPartnerProjectProfile($partnerId: MongoID!, $projectId: MongoID!) {
    getPartnerProject(partner_id: $partnerId, project_id: $projectId) {
      _id
      name
      account
    }
  }
`

export const GetPartnerProjectPhysician = gql`
  query GetPartnerProjectPhysician($partnerId: MongoID!, $projectId: MongoID!) {
    getPartnerProject(partner_id: $partnerId, project_id: $projectId) {
      _id
      name
      physician {
        name {
          prefix
          first
          last
        }
        npi
      }
    }
  }
`

export const GetPartnerProjectBranding = gql`
  query GetPartnerProjectBranding($partnerId: MongoID!, $projectId: MongoID!) {
    getPartnerProject(partner_id: $partnerId, project_id: $projectId) {
      _id
      name
      branding {
        sidebar_color
        icon_selected
        button_background
        button_text
        text_link
      }
      logo {
        url
      }
    }
  }
`

export const GetPartnerProjectNotifications = gql`
  query GetPartnerProjectNotifications(
    $partnerId: MongoID!
    $projectId: MongoID!
  ) {
    getPartnerProject(partner_id: $partnerId, project_id: $projectId) {
      notifications {
        _id
        name
        description
        enabled
      }
      notification_email
    }
  }
`

export const GetPartnerProjectSettings = gql`
  query GetPartnerProjectSettings($partnerId: MongoID!, $projectId: MongoID!) {
    getPartnerProject(partner_id: $partnerId, project_id: $projectId) {
      _id
      name
      consumers_title
      timezone
      partner_code
      use_co_branding
      use_shop
      provider {
        _id
        name
      }
      kits {
        kit_id
        ls_price
        retail_price
        ecommerce_price
      }
    }
  }
`

export const GetPartnerTeamMember = gql`
  query GetPartnerTeamMember($team_member_id: MongoID!) {
    getPartnerTeamMember(team_member_id: $team_member_id) {
      profile_photo {
        url
      }
      role {
        key
      }
      name {
        first
        last
      }
      email
      academic_title
      job_title
      member_bio
    }
  }
`

export const ListPartners = gql`
  query ListPartners(
    $filter: PartnerFilter
    $sort: PartnerSort = { name: asc }
    $page: NonNegativeInt = 1
  ) {
    listPartners(limit: 40, filter: $filter, sort: $sort, page: $page) {
      partners {
        _id
        name
        contact {
          name {
            display
          }
        }
        created_at
        status
      }
      page
      pages
      total
    }
  }
`

export const ListPartnerProjects = gql`
  query ListPartnerProjects(
    $partnerId: MongoID!
    $filter: NameFilter
    $sort: NameSort = { name: asc }
    $page: NonNegativeInt = 1
  ) {
    listPartnerProjects(
      partner_id: $partnerId
      limit: 40
      filter: $filter
      sort: $sort
      page: $page
    ) {
      projects {
        _id
        name
        provider {
          _id
          name
        }
        status
        created_at
      }
      page
      pages
    }
  }
`

export const ListPartnerProjectTeamMembers = gql`
  query ListPartnerProjectTeamMembers(
    $partnerId: MongoID!
    $projectId: MongoID!
  ) {
    listPartnerProjectTeamMembers(
      partner_id: $partnerId
      project_id: $projectId
      limit: 20
      filter: { belongs_to_project: false }
    ) {
      partner_team_members {
        _id
        name {
          display
        }
      }
    }
  }
`

export const ListPartnerProjectTeamMembersTab = gql`
  query ListPartnerProjectTeamMembersTab(
    $partnerId: MongoID!
    $projectId: MongoID!
    $limit: PositiveInt
    $filter: PartnerProjectTeamMemberFilter
    $sort: TeamMemberSort
  ) {
    listPartnerProjectTeamMembers(
      partner_id: $partnerId
      project_id: $projectId
      limit: $limit
      filter: $filter
      sort: $sort
    ) {
      partner_team_members {
        _id
        profile_photo {
          url
        }
        name {
          first
          last
          display
        }
        email
        role {
          name
        }
        status
      }
      page
      pages
      total
    }
  }
`

export const ListPartnerTeamMembers = gql`
  query ListPartnerTeamMembers(
    $partnerId: MongoID!
    $limit: PositiveInt
    $filter: NameFilter
    $sort: TeamMemberSort
    $page: NonNegativeInt = 1
  ) {
    listPartnerTeamMembers(
      partner_id: $partnerId
      limit: $limit
      filter: $filter
      sort: $sort
      page: $page
    ) {
      partner_team_members {
        _id
        profile_photo {
          url
        }
        name {
          first
          last
          display
        }
        email
        role {
          name
        }
        status
        joined
      }
      page
      pages
      total
    }
  }
`

export const ListPlatformRoles = gql`
  query ListPlatformRoles($platform: Platform) {
    listPlatformRoles(platform: $platform) {
      name
      key
    }
  }
`

export const ListPartnerMiscPricing = gql`
  query ListPartnerMiscPricing($partnerId: MongoID!, $providerId: MongoID!) {
    listPartnerMiscPricing(partner_id: $partnerId, provider_id: $providerId) {
      misc_pricing {
        name
        code
        contract_price
        retail_price
      }
    }
  }
`

export const ListHookEvents = gql`
  query ListHookEvents {
    listHookEvents
  }
`
export const ListHookActions = gql`
  query ListHookActions {
    listHookActions
  }
`
