import React from 'react'

import {
  InfiniteScrollList,
  ListRow,
  List,
  ListHeader,
  ListLoader,
  Card,
} from '@labsavvyapp/ui-components'
import sharedStyle from '../../../../../styles/shared.module.css'

import {
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/ui-components/lib/utils'

import style from './KitsList.module.css'
import classNames from 'classnames'
import { generateProviderName } from '../../../../Kits/utils'

export default function KitsList({ data, labList, fetchMore, loading }) {
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Kit Name',
            className: style.column,
          },
          {
            name: 'Laboratory',
            className: style.column,
          },
          {
            name: 'Description',
            className: style.column,
          },
          {
            name: 'SKU Kit',
            className: style.column,
          },
          {
            name: 'SKU Lab',
            className: style.column,
          },
          {
            name: '# of Panels',
            className: style.column,
          },
        ]}
      />
      <InfiniteScrollList
        dataLength={data.length}
        scrollableTarget="packages-list"
        hasMore={hasMore(pagination)}
        next={() =>
          fetchNext('listPartnerProjectPackages', 'packages', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && isEmpty(data) && (
          <Card data-test="EmptyCard" emptyCard>
            <b>No kits were found associated to this project. </b> You may
            manage the kits associated with this project.
          </Card>
        )}

        {data.map((kit) => (
          <ListRow
            key={kit?._id}
            className={classNames(sharedStyle.listRow, style.row)}
          >
            <div className={style.column}>{kit?.name} </div>
            <div className={style.column}>
              {generateProviderName(kit?.provider_id, labList)}
            </div>
            <div className={style.column}>{kit?.description}</div>
            <div className={style.column}>{kit?.sku_kit_id}</div>
            <div className={style.column}>{kit?.sku_lab_id}</div>
            <div className={style.column}>{kit?.compendia_codes.length}</div>
          </ListRow>
        ))}

        <ListLoader
          fetched={data.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
