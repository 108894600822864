import React from 'react'
import { useHistory } from 'react-router'
import { Button } from '@labsavvyapp/ui-components'

//Filler query
// import { ListProviders } from '../../../graphql/providers/queries'

import { SETTINGS } from '../../../config/routes'

export default function ConfigurationRow({ data }) {
  const { push } = useHistory()

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid #ECECEB',
      }}
      key={data.index}
    >
      <div style={{ width: '60%', flexDirection: 'column' }}>
        <div style={{ fontWeight: 600, fontSize: 16 }}>{data.name} </div>
        <div style={{ fontWeight: 200, fontSize: 14 }}>{data.description}</div>
      </div>

      <div style={{ width: '20%', textAlign: 'right', paddingRight: 30 }}></div>

      <div style={{ width: '20%', textAlign: 'center', paddingRight: 30 }}>
        <Button
          onClick={() =>
            push({
              pathname: SETTINGS.editConfiguration,
              state: { config: data },
            })
          }
          size="small"
        >
          Edit
        </Button>
      </div>
    </div>
  )
}
