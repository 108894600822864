import gql from 'graphql-tag'

export const GET_COMPENDIUM_TESTS = gql`
  query ListCompendiumTests(
    $limit: NonNegativeInt = 200
    $page: NonNegativeInt = 1
    $providerId: MongoID!
    $search: RegExp
  ) {
    listCompendiumTests(
      limit: $limit
      page: $page
      providerId: $providerId
      search: $search
    ) {
      compendium_tests {
        _id
        order {
          code
          name
        }
        result {
          code
          name
        }
        test_reference {
          _id
          name
        }
        meta {
          loinc
          specimens {
            _id
            domain
            subdomain
            category
            subcategory
            code
            subcode
            name
            description
            placeholder
            value
            value2
            value3
            created_at
            updated_at
            deleted_at
          }
          containers {
            _id
            domain
            subdomain
            category
            subcategory
            code
            subcode
            name
            description
            placeholder
            value
            value2
            value3
            created_at
            updated_at
            deleted_at
          }
          containers_external {
            code
            name
          }
        }
      }
      page
      pages
      total
    }
  }
`

export const GET_COMPENDIUM_PANELS = gql`
  query ListCompendiumPanels(
    $limit: PositiveInt = 900
    $page: PositiveInt = 1
    $type: CompendiumPanelTypeEnum!
    $search: RegExp
    $providerId: MongoID!
    $sort: CompendiumSort
  ) {
    listCompendiumPanels(
      limit: $limit
      page: $page
      filter: { type: $type, providerId: $providerId, search: $search }
      sort: $sort
    ) {
      compendium_panels {
        name
        code
      }
      page
      pages
      total
    }
  }
`

export const GET_COMPENDIUM_PANELS_AND_TESTS = gql`
  query ListCompendiumPanels(
    $limit: PositiveInt = 20
    $page: PositiveInt = 1
    $type: CompendiumPanelTypeEnum!
    $search: RegExp
    $providerId: MongoID!
    $sort: CompendiumSort
  ) {
    listCompendiumPanels(
      limit: $limit
      page: $page
      filter: { type: $type, providerId: $providerId, search: $search }
      sort: $sort
    ) {
      compendium_panels {
        name
        code
        tests {
          _id
          order {
            name
            code
          }
          result {
            name
            code
          }
        }
      }
      page
      pages
      total
    }
  }
`
