import React from 'react'
import MainContainer from '../../../../../components/MainContainer/MainContainer.js'
import { Button } from '@labsavvyapp/components'
import { GetPartnerWebhookEventSettings } from '../../../../../graphql/partner/queries.js'
import styled from '@emotion/styled'
import { useQuery } from 'react-apollo'
import { useHistory, useParams } from 'react-router'
import { WebhookEventsList } from './WebhookEventsList'

const AddInternalEvent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: -50px;
`

export const WebhookEventsPage = () => {
  const { partnerId } = useParams()
  const { push } = useHistory()

  const {
    data: webhookEvents,
    loading: webhookEventsLoading,
    fetchMore: fetchMoreWebhookEvents,
  } = useQuery(GetPartnerWebhookEventSettings, {
    variables: { id: partnerId },
  })

  return (
    <MainContainer>
      <AddInternalEvent>
        <Button
          onClick={() => push('add-webhook-event')}
          variant="filled"
          uppercase
        >
          Add Webhook Event
        </Button>
      </AddInternalEvent>
      <WebhookEventsList
        data={webhookEvents?.getPartner?.hooks?.webhook}
        loading={webhookEventsLoading}
        fetchMore={fetchMoreWebhookEvents}
      />
    </MainContainer>
  )
}
