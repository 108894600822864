import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import {
  Button,
  ToolbarHeader,
  TextInput,
  Select,
  Grid,
  LoadingOverlay,
  LoadingModal,
  TransferList,
  useDisclosure,
  useForm,
} from '@labsavvyapp/components'

import { useMutation, useQuery } from 'react-apollo'
import { useHistory, useParams } from 'react-router'
import MainContainer from '../../../components/MainContainer/MainContainer'
import { Helmet } from 'react-helmet-async'
import { UPDATE_KIT } from '../../../graphql/kits/mutations'
import { KIT } from '../../../config/routes'
import { ListProviders } from '../../../graphql/providers/queries'
import { LIST_CONFIGS } from '../../../graphql/settings/queries'
import {
  BRANDS_FILTER,
  CATEGORIES_FILTER,
  FULFILLMENTS_FILTER,
  KIT_FORM_INITIAL_VALUES,
  KIT_FORM_VALIDATIONS,
} from '../constants'
import { GET_KIT } from '../../../graphql/kits/queries'
import { GET_COMPENDIUM_PANELS } from '../../../graphql/compendia/queries'
import {
  ButtonGroup,
  EmptyContainer,
  ErrorMessage,
  FormSection,
  PanelSection,
  StickyFooter,
} from '../KitPage.styles'

export const ManageKitPage = () => {
  const { push } = useHistory()
  const { id } = useParams()
  const [
    isLoadingOverlayVisible,
    { open: openLoadingOverlay, close: closeLoadingOverlay },
  ] = useDisclosure(false)

  const [
    isSavingModalOpen,
    { open: openSavingModal, close: closeSavingModal },
  ] = useDisclosure(false)

  const { data: kitData, loading: kitDataLoading } = useQuery(GET_KIT, {
    variables: {
      id: id,
    },
  })
  const [compendiaSearch, setCompendiaSearch] = useState(['', ''])

  const form = useForm({
    initialValues: KIT_FORM_INITIAL_VALUES,
    validate: KIT_FORM_VALIDATIONS,
  })

  const { data: compendiaPanelsData, loading: compendiaPanelsLoading } =
    useQuery(GET_COMPENDIUM_PANELS, {
      skip: !form.values.provider_id,
      variables: {
        providerId: form.values.provider_id,
        type: 'group',
        search: compendiaSearch[0],
      },
    })

  const { data: categoryList } = useQuery(LIST_CONFIGS, {
    variables: {
      filter: CATEGORIES_FILTER,
    },
  })

  const { data: brandsList } = useQuery(LIST_CONFIGS, {
    variables: {
      filter: BRANDS_FILTER,
    },
  })

  const { data: fullfillmentsList } = useQuery(LIST_CONFIGS, {
    variables: {
      filter: FULFILLMENTS_FILTER,
    },
  })

  const { data: providerList } = useQuery(ListProviders, {
    variables: {},
  })

  const initialValues = [[], []]

  const [updateKit, { loading: updateKitLoading, error: updateKitError }] =
    useMutation(UPDATE_KIT)

  const [categoriesData, setCategoriesData] = useState([])
  const [brandsData, setBrandsData] = useState([])
  const [fulfillmentsData, setFulfillmentsData] = useState([])
  const [providerData, setProviderData] = useState([])
  const [compediumData, setCompendiumData] = useState(initialValues)

  useEffect(() => {
    if (!compendiaPanelsLoading) {
      const availablePanels = _.map(
        compendiaPanelsData?.listCompendiumPanels?.compendium_panels,
        (compendia) => ({
          value: compendia.code,
          label: `${compendia.code} - ${compendia.name}`,
        }),
      )
      const selectedPanelCodes = form.values.compendia_codes
      const selectedPanels = _.filter(availablePanels, (item) =>
        _.includes(selectedPanelCodes, item.value),
      )
      const availablePanelsFinal = availablePanels.filter(
        (panel) => !selectedPanelCodes.includes(panel.value),
      )

      const compendiaData = [availablePanelsFinal, selectedPanels]
      setCompendiumData(compendiaData)
    }
  }, [compendiaPanelsData])

  useEffect(() => {
    const compendiaCodes = _.map(compediumData[1], 'value')
    form.setFieldValue('compendia_codes', compendiaCodes)
  }, [compediumData])

  useEffect(() => {
    if (!kitDataLoading) {
      const values = _.omit(kitData?.getKit, '_id')
      form.setValues(values)
      closeLoadingOverlay()
    } else {
      openLoadingOverlay()
    }
  }, [kitDataLoading])

  useEffect(() => {
    if (compendiaPanelsLoading) {
      openLoadingOverlay()
    } else {
      closeLoadingOverlay()
    }
  }, [compendiaPanelsLoading])

  useEffect(() => {
    const categoryListSelectData = _.map(
      categoryList?.listConfigs?.configs,
      (config) => ({
        value: config.value,
        label: config.name,
      }),
    )
    setCategoriesData(categoryListSelectData)
  }, [categoryList])

  useEffect(() => {
    const brandsListSelectData = _.map(
      brandsList?.listConfigs?.configs,
      (config) => ({
        value: config.value,
        label: config.name,
      }),
    )
    setBrandsData(brandsListSelectData)
  }, [brandsList])

  useEffect(() => {
    const fullfillmentsListSelectData = _.map(
      fullfillmentsList?.listConfigs?.configs,
      (config) => ({
        value: config._id,
        label: config.name,
      }),
    )
    setFulfillmentsData(fullfillmentsListSelectData)
  }, [fullfillmentsList])

  useEffect(() => {
    const providerListSelectData = _.map(
      providerList?.listProviders?.providers,
      (provider) => ({
        value: provider._id,
        label: provider.name,
      }),
    )
    setProviderData(providerListSelectData)
  }, [providerList])

  const handleBackToKitListClick = () => {
    push(KIT.base)
  }

  const handleUpdateKitClick = async (values) => {
    openSavingModal()

    await updateKit({
      variables: {
        data: values,
        id: kitData?.getKit?._id,
      },
    })

    setTimeout(() => {
      if (!updateKitLoading) {
        handleBackToKitListClick()
      }
    }, 2000)
  }

  return (
    <MainContainer>
      <Helmet>
        <title>Manage Kit</title>
      </Helmet>

      <form onSubmit={form.onSubmit((values) => handleUpdateKitClick(values))}>
        <ToolbarHeader
          title="Manage kit"
          backButtonAction={handleBackToKitListClick}
          backButtonText="Back to Kit List"
        />
        <FormSection>
          <Grid.Col md={6} lg={2}>
            <TextInput
              label="Name"
              placeholder="Kit Name"
              withAsterisk
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={4}>
            <TextInput
              label="Description"
              placeholder="Description"
              withAsterisk
              {...form.getInputProps('description')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={2}>
            <Select
              data={providerData}
              label="Laboratory"
              placeholder="Select a Laboratory"
              searchable
              withAsterisk
              disabled
              {...form.getInputProps('provider_id')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={2}>
            <TextInput
              label="SKU Kit"
              placeholder="SKU Kit ID"
              withAsterisk
              {...form.getInputProps('sku_kit_id')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={2}>
            <TextInput
              label="SKU Lab"
              placeholder="SKU Lab ID"
              withAsterisk
              {...form.getInputProps('sku_lab_id')}
            />
          </Grid.Col>
        </FormSection>
        <FormSection>
          <Grid.Col md={6} lg={3}>
            <TextInput
              label="Short Name"
              placeholder="Short Name"
              {...form.getInputProps('short_name')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={3}>
            <TextInput
              label="Code"
              placeholder="Code"
              {...form.getInputProps('code')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={2}>
            <Select
              data={categoriesData}
              label="Category"
              placeholder="Select a Category"
              searchable
              disabled={categoriesData.length === 0}
              {...form.getInputProps('category')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={2}>
            <Select
              data={brandsData}
              label="Brand"
              placeholder="Select a Brand"
              searchable
              disabled={brandsData.length === 0}
              {...form.getInputProps('brand')}
            />
          </Grid.Col>
          <Grid.Col md={6} lg={2}>
            <Select
              data={fulfillmentsData}
              label="Fulfillment"
              placeholder="Select a Fulfillment"
              searchable
              withAsterisk
              disabled={fulfillmentsData.length === 0}
              {...form.getInputProps('fulfillment')}
            />
          </Grid.Col>
        </FormSection>
        <PanelSection>
          <TransferList
            value={compediumData}
            onChange={setCompendiumData}
            searchPlaceholder="Search..."
            nothingFound="Nothing here"
            listHeight={'450px'}
            searchValues={compendiaSearch}
            onSearch={setCompendiaSearch}
            titles={[
              'Available Panels from laboratory',
              'Selected Panels for this Kit',
            ]}
          />
          <ErrorMessage>{form.errors['compendia_codes']}</ErrorMessage>
        </PanelSection>

        <PanelSection>
          <Button
            radius="xl"
            size="sm"
            uppercase
            variant="basic"
            onClick={() => {}}
          >
            + Add Metadata
          </Button>
        </PanelSection>
        <StickyFooter>
          <EmptyContainer>s</EmptyContainer>
          <ButtonGroup>
            <Button
              radius="xl"
              size="md"
              uppercase
              variant="subtle"
              color="pink"
              onClick={handleBackToKitListClick}
            >
              Cancel
            </Button>
            <Button
              radius="xl"
              size="md"
              uppercase
              variant="filled"
              type="submit"
            >
              Update Kit
            </Button>
          </ButtonGroup>
        </StickyFooter>
      </form>

      <LoadingModal
        loadingMessage="We are saving your changes... please wait"
        successMessage="Your data has been saved"
        errorMessage="Something went wrong. Please try again later."
        isLoading={updateKitLoading}
        opened={isSavingModalOpen}
        onClose={closeSavingModal}
        hasError={updateKitError}
      />

      {isLoadingOverlayVisible && (
        <LoadingOverlay visible={isLoadingOverlayVisible} overlayBlur={2} />
      )}
    </MainContainer>
  )
}
