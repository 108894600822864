import React from 'react'

import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  ListRow,
  Card,
} from '@labsavvyapp/ui-components'
import {
  Skeleton,
  RefreshButton,
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/components'

import { KIT } from '../../../../../../config/routes'
import sharedStyle from '../../../../../../styles/shared.module.css'
import style from './InternalEventsList.module.css'
import classNames from 'classnames'
import { useHistory } from 'react-router'

export const InternalEventsList = ({
  data = { subscribers: [] },
  loading,
  fetchMore,
}) => {
  const { push } = useHistory()
  const { page, pages, total, subscribers } = data

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Event Code',
            className: style.column,
          },
          {
            name: '',
            className: style.divider,
          },
          {
            name: 'Trigger Code',
            className: style.column,
          },
        ]}
      />

      {loading && (
        <ListRow
          data-test="package-list-row"
          className={classNames(sharedStyle.listRow, style.row)}
          onClick={() => push(`${KIT.base}`)}
        >
          <div className={style.column}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />
          </div>
          <div className={style.column}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />{' '}
          </div>
          <div className={style.column}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />{' '}
          </div>
        </ListRow>
      )}

      {subscribers && (
        <InfiniteScrollList
          dataLength={subscribers?.length}
          scrollableTarget="package-list"
          hasMore={hasMore({ page, pages })}
          next={() =>
            fetchNext('listCompendiumTests', 'kits', {
              page,
              fetchMore,
            })
          }
        >
          {!loading && isEmpty(subscribers) && (
            <Card
              data-test="empty-card"
              emptyCard
              className={style.notFoundMessage}
            >
              <b>No events were configured for this partner. </b> If you feel
              this is an error, you may try to <RefreshButton />
              &mdash; this may be a momentary issue.
            </Card>
          )}

          {subscribers.map((subscriber) => (
            <ListRow
              data-test="package-list-row"
              key={`${subscriber}_${Math.random()}`}
              className={classNames(sharedStyle.listRow, style.row)}
            >
              <div className={style.column}>{subscriber.event_code}</div>
              <div className={style.divider}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-big-right"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z" />
                </svg>
              </div>
              <div className={style.column}>{subscriber.sub}</div>
            </ListRow>
          ))}

          <ListLoader
            fetched={subscribers.length}
            total={total}
            loading={loading}
          />
        </InfiniteScrollList>
      )}
    </List>
  )
}
