import React from 'react'

import classnames from 'classnames'
import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  Card,
} from '@labsavvyapp/ui-components'
import {
  RefreshButton,
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/components'

import style from './TeamMembersList.module.css'
import TeamMembersListRow from './TeamMembersListRow'

export default function TeamMembersList({ data, fetchMore, loading }) {
  let items = []
  let pagination = {
    page: 0,
    pages: 0,
    total: 0,
  }

  if (data) {
    items = data.listPartnerTeamMembers.partnerTeamMembers
    pagination.page = data.listPartnerTeamMembers.page
    pagination.pages = data.listPartnerTeamMembers.pages
    pagination.total = data.listPartnerTeamMembers.total
  }

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Name',
            className: classnames(style.column, style.imageNameColumn),
          },
          { name: 'Email/ Username', className: style.column },
          {
            name: 'Role',
            className: style.column,
          },
          {
            name: 'Joined',
            className: style.column,
          },
          {
            name: 'Status',
            className: style.column,
          },
        ]}
      />
      <InfiniteScrollList
        dataLength={items.length}
        scrollableTarget="team-members-list"
        hasMore={hasMore({ page: pagination.page, pages: pagination.pages })}
        next={() =>
          fetchNext('listPartnerTeamMembers', 'partner_team_members', {
            page: pagination.page,
            fetchMore,
          })
        }
      >
        {!loading && isEmpty(items) && (
          <Card data-test="EmptyCard" emptyCard>
            <b>No team members were found. </b> If you feel this is an error,
            you may try to <RefreshButton />
            &mdash; this may be a momentary issue.
          </Card>
        )}

        {!isEmpty(items) && <TeamMembersListRow items={items} />}

        <ListLoader
          fetched={items.length}
          total={pagination.total}
          loading={loading}
        />
      </InfiniteScrollList>
    </List>
  )
}
