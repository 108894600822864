export const HOME = '/'

const SIGN_BASE = '/sign'
export const SIGN = {
  base: SIGN_BASE,
  account: `${SIGN_BASE}/account`,
  in: `${SIGN_BASE}/in`,
  out: `${SIGN_BASE}/out`,
  profile: `${SIGN_BASE}/profile`,
  recover: `${SIGN_BASE}/recover`,
  recoverConfirmToken: `${SIGN_BASE}/recover/confirm-token`,
  recoverNewPassword: `${SIGN_BASE}/recover/new-password`,
  recoverFinish: `${SIGN_BASE}/recover/finish`,
}

const PROFILE_BASE = '/profile'
export const PROFILE = {
  base: PROFILE_BASE,
  accountVerification: `${PROFILE_BASE}/account-verification`,
  newPassword: `${PROFILE_BASE}/new-password`,
  passwordUpdated: `${PROFILE_BASE}/password-updated`,
}

const CATEGORIES_BASE = '/categories'
export const CATEGORIES = {
  base: CATEGORIES_BASE,
}

const PARTNER_BASE = '/partner'
const ADMIN_BASE = `${PARTNER_BASE}/:partnerId/admin`
const PROJECT_BASE = `${PARTNER_BASE}/:partnerId/project`
export const PARTNERS = {
  list: '/partners',
  base: PARTNER_BASE,
  new: `${PARTNER_BASE}/new`,
  details: `${PARTNER_BASE}/:partnerId`,
  admin: {
    base: ADMIN_BASE,
    section: `${ADMIN_BASE}/:section`,
    subSection: `${ADMIN_BASE}/:section/:subSection`,
    editTeamMember: `${ADMIN_BASE}/:section/edit/:teamMemberId`,
  },
  projects: {
    new: `${PROJECT_BASE}/new`,
    admin: {
      base: `${PROJECT_BASE}/:projectId`,
      section: `${PROJECT_BASE}/:projectId/:section`,
      subSection: `${PROJECT_BASE}/:projectId/:section/:subSection`,
    },
  },
}

const MAIN_PRICING_BASE = '/main-pricing'
export const MAIN_PRICING = {
  base: MAIN_PRICING_BASE,
}

const TEAM_MEMBERS_BASE = '/team-members'
export const TEAM_MEMBERS = {
  base: TEAM_MEMBERS_BASE,
  invite: `${TEAM_MEMBERS_BASE}/invite`,
  manage: `${TEAM_MEMBERS_BASE}/:id`,
}

const PACKAGES_BASE = '/packages'
export const PACKAGES = {
  base: PACKAGES_BASE,
  new: `${PACKAGES_BASE}/new`,
  manage: `${PACKAGES_BASE}/:packageId`,
}

const PACKAGE_CATEGORIES_BASE = '/package-categories'
export const PACKAGE_CATEGORIES = {
  base: PACKAGE_CATEGORIES_BASE,
  new: `${PACKAGE_CATEGORIES_BASE}/new`,
  edit: `${PACKAGE_CATEGORIES_BASE}/:packageCategoryId`,
}

const SETTINGS_BASE = '/settings'
export const SETTINGS = {
  base: SETTINGS_BASE,
  maintenance: `${SETTINGS_BASE}/maintenance`,
  configuration: `${SETTINGS_BASE}/configuration`,
  addConfiguration: `${SETTINGS_BASE}/configuration/add`,
  editConfiguration: `${SETTINGS_BASE}/configuration/edit`,
  dataManagement: `${SETTINGS_BASE}/data-import`,
  emailManagement: `${SETTINGS_BASE}/email-management`,
}

const ERROR_BASE = '/error'
export const ERROR = {
  unauthorized: `${ERROR_BASE}/401`,
  notFound: `${ERROR_BASE}/404`,
}

const COMPENDIA_BASE = '/compendia'
export const COMPENDIA = {
  base: COMPENDIA_BASE,
  new: `${COMPENDIA_BASE}/new`,
  view: `${COMPENDIA_BASE}/:laboratoryId/view/:id/:name`,
  manage: `${COMPENDIA_BASE}/:laboratoryId/manage/:id/:name`,
}

const KIT_BASE = '/kit'
export const KIT = {
  base: KIT_BASE,
  new: `${KIT_BASE}/new`,
  manage: `${KIT_BASE}/manage/:id`,
}
