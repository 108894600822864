import React from 'react'
import _ from 'lodash'

import {
  InfiniteScrollList,
  List,
  ListHeader,
  ListLoader,
  ListRow,
  Card,
} from '@labsavvyapp/ui-components'
import {
  Skeleton,
  RefreshButton,
  fetchNext,
  hasMore,
  isEmpty,
} from '@labsavvyapp/components'

import { KIT } from '../../../config/routes'
import sharedStyle from '../../../styles/shared.module.css'
import style from './KitsList.module.css'
import classNames from 'classnames'
import { generatePath, useHistory } from 'react-router'
import { generateFulfillmentName, generateProviderName } from '../utils'

export const KitsList = ({
  data = { kits: [] },
  loading,
  fetchMore,
  searchKey,
  userSelectedProvider,
  providerList,
  fulfillmentList,
}) => {
  const { push } = useHistory()

  const { page, pages, total, kits } = data

  const sortKitsByProvider = _.filter(kits, {
    provider_id: userSelectedProvider?._id,
  })

  const filteredKitsData = _.filter(
    sortKitsByProvider,
    (kit) =>
      kit.description.toLowerCase().includes(searchKey.toLowerCase()) ||
      kit.name.toLowerCase().includes(searchKey.toLowerCase()) ||
      kit.sku_kit_id.toLowerCase().includes(searchKey.toLowerCase()) ||
      kit.sku_lab_id.toLowerCase().includes(searchKey.toLowerCase()) ||
      kit.short_name.toLowerCase().includes(searchKey.toLowerCase()),
  )

  return (
    <List>
      <ListHeader
        className={style.header}
        columns={[
          {
            name: 'Name',
            className: style.column,
          },
          {
            name: 'Laboratory',
            className: style.column,
          },
          {
            name: 'Fulfillment',
            className: style.column,
          },
          {
            name: 'Description',
            className: style.column,
          },

          {
            name: 'SKU Kit',
            className: style.column,
          },
          {
            name: 'SKU Lab',
            className: style.column,
          },

          {
            name: '# of Panels',
            className: style.column,
          },
        ]}
      />

      {loading && (
        <ListRow
          data-test="package-list-row"
          className={classNames(sharedStyle.listRow, style.row)}
          onClick={() => push(`${KIT.base}`)}
        >
          <div className={style.column}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />
          </div>
          <div className={style.column}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />{' '}
          </div>
          <div className={style.column}>
            <Skeleton height={8} mt={6} width="70%" radius="xl" />{' '}
          </div>
        </ListRow>
      )}

      {kits && (
        <InfiniteScrollList
          dataLength={kits?.length}
          scrollableTarget="package-list"
          hasMore={hasMore({ page, pages })}
          next={() =>
            fetchNext('listCompendiumTests', 'kits', {
              page,
              fetchMore,
            })
          }
        >
          {!loading && isEmpty(filteredKitsData) && (
            <Card
              data-test="empty-card"
              emptyCard
              className={style.notFoundMessage}
            >
              <b>No kits were found. </b> You may try changing the selected
              laboratory above. If you feel this is an error, you may try to{' '}
              <RefreshButton />
              &mdash; this may be a momentary issue.
            </Card>
          )}

          {Object.keys(filteredKitsData).map((compendiumData) => (
            <ListRow
              data-test="package-list-row"
              key={compendiumData}
              className={classNames(sharedStyle.listRow, style.row)}
              onClick={() => {
                push(
                  generatePath(KIT.manage, {
                    id: filteredKitsData[compendiumData]._id,
                  }),
                )
              }}
            >
              <div className={style.column}>
                {filteredKitsData[compendiumData].name}
              </div>
              <div className={style.column}>
                {generateProviderName(
                  filteredKitsData[compendiumData].provider_id,
                  providerList,
                )}
              </div>
              <div className={style.column}>
                {generateFulfillmentName(
                  filteredKitsData[compendiumData].fulfillment,
                  fulfillmentList,
                )}
              </div>
              <div className={style.column}>
                {filteredKitsData[compendiumData].description}
              </div>
              <div className={style.column}>
                {filteredKitsData[compendiumData].sku_kit_id}
              </div>
              <div className={style.column}>
                {filteredKitsData[compendiumData].sku_lab_id}
              </div>
              <div className={style.column}>
                {filteredKitsData[compendiumData].compendia_codes.length}
              </div>
            </ListRow>
          ))}

          <ListLoader fetched={kits.length} total={total} loading={loading} />
        </InfiniteScrollList>
      )}
    </List>
  )
}
