import styled from '@emotion/styled'
import { Dropdown } from 'semantic-ui-react'
import { Search } from '@labsavvyapp/ui-components'

export const StyledCompendiaDropdown = styled(Dropdown)`
  width: 300px;
`

export const StyledCompendiaSearch = styled(Search)`
  width: 290px;
  margin-left: 10px;
  margin-right: 15px;
`
