import React from 'react'
import { Switch, Route } from 'react-router'

import { KIT } from '../config/routes'
import MainLayout from '../components/MainLayout/MainLayout'
import { KitPage, NewKitPage, ManageKitPage } from '../pages'

export default function KitRoutes({ logged, user }) {
  return (
    <MainLayout noPadding logged={logged} user={user}>
      <Switch>
        <Route exact path={KIT.base} component={KitPage} />
        <Route exact path={KIT.new} component={NewKitPage} />
        <Route exact path={KIT.manage} component={ManageKitPage} />
      </Switch>
    </MainLayout>
  )
}
